import React from 'react';
import MenuBg from '../img/services-bg.jpg';

export default class Menu extends React.Component {
    constructor(props){
      super(props);
      this.state={
        open: this.props.open? this.props.open:false,
      }
    }
      
    componentWillReceiveProps(nextProps){
      if(nextProps.open !== this.state.open){
        this.setState({open:nextProps.open});
      }
    }

    render(){
      const styles={
        container: {
          position: 'fixed',
          top: 0,
          left: 0,
          height: this.state.open ? '100%': 0,
          width: this.state.open ? '100%': 0,
          display: 'flex',
          flexDirection: 'column',
          background: "url(" + MenuBg + ")",
          backgroundPosition: 'center',
          backgroundRepeat: 'repeat',
          opacity: this.state.open ? 1 : 0,
          animation: this.state.open ? 'fadeIn ease 0.4s' : 'fadeOut ease 10s',
          color: '#fafafa',
        },
        menuList: {
          paddingTop: '5rem',
        },
      }
      return(
        <div style={styles.container}>
          {
            this.state.open?
              <div style={styles.menuList}>
                {this.props.children}
              </div> : null
          }
        </div>
      )
    }
  }