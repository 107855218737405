import React from 'react';

export default class MenuButton extends React.Component {
    constructor(props){
      super(props);
      this.state={
        open: this.props.open? this.props.open:false,
        color: this.props.color? this.props.color:'black',
        prevScrollpos: window.pageYOffset,
        lineBg: true,
      }
    }
  
    componentWillReceiveProps(nextProps){
      if(nextProps.open !== this.state.open){
        this.setState({open:nextProps.open});
      }
      window.addEventListener("scroll", this.handleScroll);
    }
    componentWillUnmount() {
      window.removeEventListener("scroll", this.handleScroll);
    }
    handleClick(){
    this.setState({open:!this.state.open});
    }
    handleScroll = () => {  
      const currentScrollPos = window.pageYOffset;
      const lineBg = window.pageYOffset < 500;
      this.setState({
        prevScrollpos: currentScrollPos,
        lineBg,
      });
    };
    
    render(){
      const styles = {
        container: {
          position: 'fixed',
          height: '32px',
          width: '32px',
          display:'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          padding: '4px',
          marginLeft: '15px',
          marginTop: '5px',
          right: '4vw',
          zIndex: 99,
        },
        line: {
          height: '2px',
          width: '24px',
          background: this.state.lineBg ? '#fff' : (this.state.open ? '#fff' : '#00091F'),
          transition: 'all 0.3s ease',
        },
        lineTop: {
          transform: this.state.open ? 'rotate(45deg)':'none',
          transformOrigin: 'top left',
          marginBottom: '5px',
        },
        lineMiddle: {
          height: '2px',
          opacity: this.state.open ? 0: 1,
          transform: this.state.open ? 'translateX(-16px)':'none',
        },
        lineBottom: {
          transform: this.state.open ? 'translateX(-1px) rotate(-45deg)':'none',
          transformOrigin: 'top left',
          marginTop: '5px',
        },       
      }
      return(
        <div style={styles.container} 
          onClick={this.props.onClick ? this.props.onClick: 
            ()=> {this.handleClick();}}>
          <div style={{...styles.line,...styles.lineTop}}/>
          <div style={{...styles.line,...styles.lineMiddle}}/>
          <div style={{...styles.line,...styles.lineBottom}}/>
        </div>
      )
    }
  }
  