import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../img/colibri_bird.png';
import Hidden from '@material-ui/core/Hidden';
import MenuButton from './MenuButton';
import MenuItem from './MenuItem';
import Menu from './Menu';
import classnames from "classnames";
import CallBack from "./CallBack";

class Header extends React.Component {
  constructor(props){
    super(props);
    this.state={
      menuOpen:false,
      open: false,
      prevScrollpos: window.pageYOffset,
      navBg: true,
      visible: true,
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const visible = window.pageYOffset < 500;
    const navBg = window.pageYOffset < 500;
    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
      navBg
    });
  };
  
  handleClickOpen = () => {
    this.setState({
      menuOpen: false,
      open: true,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleMenuClick() {
    this.setState({menuOpen:!this.state.menuOpen});
  }
  
  handleLinkClick() {
    this.setState({
      menuOpen: false,
    });
  }

  render() {
    return (
      <div style={{zIndex: 5}} className={classnames("header", {
        "navbar--hidden": !this.state.visible, "topNavBg": !this.state.navBg
      })}>
      
      <Hidden xsDown mdDown implementation="css">
      
    
      <ul id="Navigation">
      {this.state.visible ? 
      <li style={{float:"left"}}>
      <a href="/" style={{textTransform: 'initial'}}><h2>
      <span className="align-image">.</span><span className="align-image">Colibrisoft</span>
      <img src={Logo} alt="Logo" />
      </h2></a></li>
      :
      <li style={{float:"left"}}>
      <a href="/" style={{textTransform: 'initial'}}><h2>
      <span className="hidden-colibri-text">.Colibrisoft</span>
      </h2></a></li>
      }
      <li className="menu-items"><a href="tel:+998903552060">+998 90 355-20-60</a></li>
      <li className="request-call-wrap"><button className="request-call" onClick={this.handleClickOpen}>Оставить заявку</button></li>
      <CallBack open={this.state.open} onClose={this.handleClose} id={"header"}/>
      <li className="menu-items"><NavLink to={"/contact"} activeClassName="active">Контакты</NavLink></li>
      <li className="menu-items"><NavLink to={"/services"} activeClassName="active">Услуги</NavLink></li>
      <li className="menu-items"><NavLink to={"/about"} activeClassName="active">О нас</NavLink></li>
      <li className="menu-items"><NavLink to={"/"} activeClassName="active" exact>Главная</NavLink></li>
      

      
      </ul>
      </Hidden>
      <Hidden lgUp implementation="css">
      <ul>
      {this.state.visible ? 
      <li style={{float:"left"}}>
      <a href="/" style={{textTransform: 'initial'}}><h2>
      <span className="align-image">.</span><span className="align-image">Colibrisoft</span>
      <img src={Logo} alt="Logo" />
      </h2></a></li>
      :
      <li style={{float:"left"}}>
      <a href="/" style={{textTransform: 'initial'}}><h2>
      <span className="hidden-colibri-text">.Colibrisoft</span>
      </h2></a></li>
      }
      <MenuButton open={this.state.menuOpen} onClick={()=>this.handleMenuClick()} color='white'/>
      <Menu open={this.state.menuOpen}>
      <MenuItem
      delay={'.5s'}
      onClick={()=>{this.handleLinkClick()}}><li className="mob-menu-items"><NavLink to={"/"} activeClassName="active" exact>Главная</NavLink></li></MenuItem>
      <MenuItem
      delay={'.5s'}
      onClick={()=>{this.handleLinkClick()}}><li className="mob-menu-items"><NavLink to={"/about"} activeClassName="active">О нас</NavLink></li></MenuItem>
      <MenuItem
      delay={'.5s'}
      onClick={()=>{this.handleLinkClick()}}><li className="mob-menu-items"><NavLink to={"/services"} activeClassName="active">Услуги</NavLink></li></MenuItem>
      <MenuItem
      delay={'.5s'}
      onClick={()=>{this.handleLinkClick()}}><li className="mob-menu-items"><NavLink to={"/contact"} activeClassName="active">Контакты</NavLink></li></MenuItem>
      <MenuItem
      delay={'.5s'}
      onClick={this.handleClickOpen}><li className="mob-menu-items"><a className="request-call">Оставить заявку</a></li></MenuItem>
      <CallBack open={this.state.open} onClose={this.handleClose}/>

      <MenuItem
      delay={'.5s'}
      onClick={()=>{this.handleLinkClick()}}><li className="mob-menu-phone mob-menu-items"><a href="tel:+998903552060">+998 90 355-20-60</a></li></MenuItem>
      </Menu>
      </ul>
      </Hidden>
      </div>        
    )
  }
}

export default Header;
