import React from 'react';
import Particles from 'react-particles-js';
import CallBack from "./CallBack";
import audio from "../audio/oi-colibri.mp3";

class AboutUs extends React.Component {

  constructor(props){
    super(props);
    this.state={
      open: false,
    }
  }
  
  handleClickOpen = () => {
    this.setState({
      menuOpen: false,
      open: true,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  
  playAudio = () => {
    const audioEl = document.getElementsByClassName("audio-element")[0]
    audioEl.play()
  }
  
  render() {

    return (
		<div>
		
        <div id="main-bg-anim">
            <Particles 
                  params={{
                    "particles": {
                        "number": {
                            "value": 120,
                            "density": {
                                "enable": false
                            }
                        },
                        "size": {
                            "value": 3.5,
                            "random": true,
                            "anim": {
                                "speed": 4,
                                "size_min": 0.3
                            }
                        },
                        "line_linked": {
                            "enable": false
                        },
                        "move": {
                            "random": true,
                            "speed": 1,
                            "direction": "center",
                            "out_mode": "out"
                        }
                    },
                    "interactivity": {
                        "events": {
                            "onhover": {
                                "enable": true,
                                "mode": "bubble"
                            },
                            "onclick": {
                                "enable": false,
                                "mode": "repulse"
                            }
                        },
                        "modes": {
                            "bubble": {
                                "distance": 250,
                                "duration": 2,
                                "size": 0,
                                "opacity": 0
                            },
                            "repulse": {
                                "distance": 400,
                                "duration": 4
                            }
                        }
                    }
                }}  />
		</div>
		
		<section id="hero" style={{opacity: 1}}>
            <div className="inner">
                <div id="hero-content">
                    <h1>
                        Кто мы такие?
                    </h1> 
                </div>
                <div id="hero-content-bg">
                    <h1>
                        Кто мы такие?
                    </h1> 
                </div>
            </div>
            <div className="scroll-downs">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
          </div>
		</section>
		<div id="hero-bg-bottom"></div>
		<section id="features">
		<div className="inner">
                <div id="features-header" className="animate-in visible">
                <h2>Colibrisoft IT-Решения для Вашего Бизнеса!</h2>
        <p className="feature-description">
        Мы - интеллектуальная команда, в которой ценятся взаимоуважение, открытость и стремление к развитию.
        Наша команда занимается разработкой <strong>сайтов, веб и мобильных приложений </strong>  любого уровня сложности, многофункциональных <strong>телеграм ботов</strong>, а также комплексным <strong>продвижением бизнеса</strong> в интернете. 
        <br />
        Мы сотрудничаем со многими отечественными и зарубежными компаниями.
                    </p>
                </div>
                <div className="feature animate-in repositories visible">
        <div className="feature-illustration">
          <svg version="1.1" id="grf" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{enableBackground: 'new 0 0 512 512'}} xmlSpace="preserve">
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\t#grf .st0{fill:url(#splash_1_);}\n\t#grf .st1{fill:url(#grf-SVGID_1_);}\n\t#grf .st2{fill:url(#grf-SVGID_2_);}\n\t#grf .st3{fill:url(#grf-SVGID_3_);}\n\t#grf .st4{fill:url(#grf-SVGID_4_);}\n\t#grf .st5{fill:url(#grf-SVGID_5_);}\n\t#grf .st6{fill:url(#grf-SVGID_6_);}\n\t#grf .st7{fill:url(#grf-SVGID_7_);}\n\t#grf .st8{fill:url(#grf-SVGID_8_);}\n\t#grf .st9{fill:url(#grf-SVGID_9_);}\n\t#grf .st10{fill:url(#grf-SVGID_10_);}\n\t#grf .st11{fill:url(#grf-SVGID_11_);}\n\t#grf .st12{fill:url(#grf-SVGID_12_);}\n\t#grf .st13{fill-rule:evenodd;clip-rule:evenodd;fill:url(#grf-SVGID_13_);}\n\t#grf .st14{fill:#33CCFF;}\n\t#grf .st15{fill:#E944FF;}\n\t#grf .st16{fill:url(#grf-SVGID_14_);}\n\t#grf .st17{fill:none;}\n\t#grf .st18{fill:#2684FF;}\n\t#grf .st19{fill:url(#grf-SVGID_15_);}\n\t#grf .st20{fill:url(#grf-SVGID_16_);}\n\t#grf .st21{fill:#FBB03B;}\n\t#grf .st22{fill:url(#grf-SVGID_17_);}\n" }} />
            <linearGradient id="splash_1_" gradientUnits="userSpaceOnUse" x1="10.6275" y1="333.3887" x2="371.2216" y2="333.3887">
              <stop offset={0} style={{stopColor: '#E944FF'}} />
              <stop offset={1} style={{stopColor: '#33CCFF'}} />
            </linearGradient>
            <path id="splash" className="st0" d="M28.9,223c38.1-50.3,168.7-20.7,245.4,51.4s106.1,102.9,94.4,139.8
	c-20.6,64.7-247.1,88.8-317.5-22.1C9.3,326-3.6,265.9,28.9,223z" />
            <g id="gh-character">
              <linearGradient id="grf-SVGID_1_" gradientUnits="userSpaceOnUse" x1="204.7631" y1="352.4456" x2="253.3171" y2="352.4456" gradientTransform="matrix(0.9992 3.929022e-02 -3.929022e-02 0.9992 -50.9703 32.6952)">
                <stop offset={0} style={{stopColor: '#0944C1'}} />
                <stop offset={1} style={{stopColor: '#188BEF'}} />
              </linearGradient>
              <path className="st1" d="M164,462.7c6.4-15.8,11.7-29.3,12.2-31.8c1.2-6.5,15-82.7,14.2-90.9c-0.8-8.2-32.8-15.2-32.8-15.2l-14.2,7.8
		c0,0-2.2,9.7-0.7,19c1.5,9.3,7.8,14.7,8.8,23.1c1.1,8.4,4.7,46,2.2,51.3s-10.5,12-15.9,28c-0.2,0.5-0.3,0.9-0.4,1.4" />
              <linearGradient id="grf-SVGID_2_" gradientUnits="userSpaceOnUse" x1="233.6962" y1="137.6623" x2="319.7896" y2="87.9563" gradientTransform="matrix(0.9992 3.929022e-02 -3.929022e-02 0.9992 -50.9703 32.6952)">
                <stop offset={0} style={{stopColor: '#E944FF'}} />
                <stop offset={1} style={{stopColor: '#FBB03B'}} />
              </linearGradient>
              <path className="st2" d="M181.1,168.6c14.9-7.8,40.5-10.4,45.4-16.7c4.9-6.3,17.5-36.4,17.5-36.4s3.5-2.6,6.9-1.6s5.2,2.1,5.2,2.1
		s-5.1,42.2-9.6,48.5c-4.6,6.3-28.3,22.4-28.3,22.4l-43-11.3C175.1,175.5,178.4,170,181.1,168.6z" />
              <linearGradient id="grf-SVGID_3_" gradientUnits="userSpaceOnUse" x1="234.3755" y1="360.5672" x2="284.9971" y2="342.1425" gradientTransform="matrix(0.9992 3.929022e-02 -3.929022e-02 0.9992 -50.9703 32.6952)">
                <stop offset={0} style={{stopColor: '#0944C1'}} />
                <stop offset={1} style={{stopColor: '#188BEF'}} />
              </linearGradient>
              <path className="st3" d="M224.4,469c0.6-6.4,1-11.1,1-12.8c0-8.7-8.8-59.9-13.7-77.3c-4.9-17.4-11.7-20.9-13.1-33.5
		c-1.4-12.6-2.7-36.3-2.7-36.3l-43.8,18.6c0,0-4.1,17.1,3.4,29.5s43.3,69.9,45,84.4c1.3,11.1-2.9,17.1-4.5,26" />
              <linearGradient id="grf-SVGID_4_" gradientUnits="userSpaceOnUse" x1="612.3471" y1="-134.3846" x2="639.6052" y2="-134.3846" gradientTransform="matrix(0.9811 0.1935 -0.1935 0.9811 -349.6927 193.1123)">
                <stop offset={0} style={{stopColor: '#EC0C43'}} />
                <stop offset={1} style={{stopColor: '#FBB03B'}} />
              </linearGradient>
              <path className="st4" d="M296.3,167.3c0,0,2.8,3.4-1.4,6.8l-17,11.3c-6.1,2,0.4,10.7,0.4,10.7l7.3-0.6l19.9-19.4L296.3,167.3z" />
              <linearGradient id="grf-SVGID_5_" gradientUnits="userSpaceOnUse" x1="297.7741" y1="60.6524" x2="310.0408" y2="60.6524" gradientTransform="matrix(0.9992 3.929022e-02 -3.929022e-02 0.9992 -50.9703 32.6952)">
                <stop offset={0} style={{stopColor: '#EC0C43'}} />
                <stop offset={1} style={{stopColor: '#FBB03B'}} />
              </linearGradient>
              <path className="st5" d="M250.5,92.4c0,0-4.3,17.7-6.5,23s12.1,0.5,12.1,0.5l0.9-23.2L250.5,92.4z" />
              <linearGradient id="grf-SVGID_6_" gradientUnits="userSpaceOnUse" x1="197.9369" y1="133.4257" x2="235.1308" y2="119.8883" gradientTransform="matrix(0.9992 3.929022e-02 -3.929022e-02 0.9992 -50.9703 32.6952)">
                <stop offset={0} style={{stopColor: '#EC0C43'}} />
                <stop offset={1} style={{stopColor: '#FBB03B'}} />
              </linearGradient>
              <polygon className="st6" points="153,155.2 146.7,189.7 173,175.5 174.2,145.8 	" />
              <linearGradient id="grf-SVGID_7_" gradientUnits="userSpaceOnUse" x1="182.9904" y1="228.8581" x2="350.2798" y2="167.9697" gradientTransform="matrix(0.9992 3.929022e-02 -3.929022e-02 0.9992 -50.9703 32.6952)">
                <stop offset={0} style={{stopColor: '#E944FF'}} />
                <stop offset={1} style={{stopColor: '#FBB03B'}} />
              </linearGradient>
              <path className="st7" d="M278,185.5c0,0-26,6.1-32.7,11.4c-8.6-5.9-12.9-11.1-21-15.7c-6.6-3.7-20.2-7.5-29.6-9.9
		c-8.9-2.2-19.5-0.2-19.5-0.2c-16,5.2-28.8,11.5-40.6,30.3c-10.6,16.9,21.4,80.2,20.1,89.9c-1.3,9.7-11.4,41.3-11.4,41.3
		s21.4-13.5,36.3-13.9c14.9-0.3,17.8,6.7,17.8,6.7s10.8-39,10.3-61.8c-0.2-9.3-1.1-27.8-2.1-46.5c9.7,4.1,26.6,7.4,38,7
		c18.9-8.5,41.9-28.6,41.9-28.6S286.3,186.3,278,185.5z" />
              <linearGradient id="grf-SVGID_8_" gradientUnits="userSpaceOnUse" x1="300.338" y1="41.3027" x2="316.8047" y2="35.3093" gradientTransform="matrix(0.9992 3.929022e-02 -3.929022e-02 0.9992 -50.9703 32.6952)">
                <stop offset={0} style={{stopColor: '#EC0C43'}} />
                <stop offset={1} style={{stopColor: '#FBB03B'}} />
              </linearGradient>
              <path className="st8" d="M259.6,65.9l-1.3,32.4c0,0-11.6-4.2-10.8-16.7S259.6,65.9,259.6,65.9z" />
              <linearGradient id="grf-SVGID_9_" gradientUnits="userSpaceOnUse" x1="354.5655" y1="127.9483" x2="385.6711" y2="116.6268" gradientTransform="matrix(0.9992 3.929022e-02 -3.929022e-02 0.9992 -50.9703 32.6952)">
                <stop offset={0} style={{stopColor: '#EC0C43'}} />
                <stop offset={1} style={{stopColor: '#FBB03B'}} />
              </linearGradient>
              <path className="st9" d="M296.1,167.6c0,0,7.6,11.1,16.6,9.6c9-1.5,17.8-10.8,17.8-10.8L296.1,167.6z" />
              <linearGradient id="grf-SVGID_10_" gradientUnits="userSpaceOnUse" x1="194.2541" y1="93.3867" x2="232.8742" y2="79.3301" gradientTransform="matrix(0.9992 3.929022e-02 -3.929022e-02 0.9992 -50.9703 32.6952)">
                <stop offset={0} style={{stopColor: '#EC0C43'}} />
                <stop offset={1} style={{stopColor: '#FBB03B'}} />
              </linearGradient>
              <path className="st10" d="M171.3,98.3c0,0,13.2,42.6,11.8,46.9s-23.1,13-30.1,10c-7-3.1-13.3-22.8-13.3-22.8s-6.7-10.5-0.7-20.8
		S169.7,92.8,171.3,98.3z" />
              <linearGradient id="grf-SVGID_11_" gradientUnits="userSpaceOnUse" x1="177.9133" y1="79.6502" x2="240.7841" y2="79.6502" gradientTransform="matrix(0.9992 3.929022e-02 -3.929022e-02 0.9992 -50.9703 32.6952)">
                <stop offset={0} style={{stopColor: '#188BEF'}} />
                <stop offset={1} style={{stopColor: '#0944C1'}} />
              </linearGradient>
              <path className="st11" d="M175.5,110.7c0,0,3.2-9.3,0.7-18c-2.4-8.7-6.3-11.4-8.8-11.7c-2.5-0.3-43.9,30.1-43.7,36.3
		c0.2,6.3,22,29.4,22,29.4s7.2,12.2,11.4,12.9c4.2,0.7,21.1,2.6,25.4,1c4.3-1.6,2.9-22.3,0.9-24c-1.9-1.7-14.9,2.4-15.9,5.4
		s1.1,8.4-0.8,9c-1.9,0.6-5.7,2.9-5.7,0.5c0-2.4,1.3-13.5-1.2-19.4c-2.5-5.9-4.2-6-3.6-9.2C156.9,119.8,168.6,111,175.5,110.7z" />
            </g>
            <linearGradient id="grf-SVGID_12_" gradientUnits="userSpaceOnUse" x1="60.4302" y1="80.9936" x2="94.1815" y2="80.9936">
              <stop offset="1.198083e-07" style={{stopColor: '#EC0C43'}} />
              <stop offset={1} style={{stopColor: '#FBB03B'}} />
            </linearGradient>
            <path className="st12" d="M92.5,78.3l-8.3-4.1c-0.1,0-0.2-0.1-0.2-0.2l-4.1-8.3c-0.5-1.1-1.6-1.6-2.6-1.6c-1.1,0-2.1,0.6-2.6,1.6
	l-4.1,8.3c0,0.1-0.1,0.2-0.2,0.2l-8.3,4.1c-2.2,1.1-2.2,4.2,0,5.3l8.3,4.1c0.1,0,0.2,0.1,0.2,0.2l4.1,8.3c0.5,1.1,1.6,1.6,2.6,1.6
	c1.1,0,2.1-0.6,2.6-1.6l4.1-8.3c0-0.1,0.1-0.2,0.2-0.2l8.3-4.1C94.7,82.5,94.7,79.4,92.5,78.3z M86.4,78.9l-3.8,3.4
	c-0.8,0.7-1.3,1.6-1.5,2.6l-1,5c-0.1,0.3-0.5,0.4-0.7,0.2L76,86.2c-0.7-0.8-1.6-1.3-2.6-1.5l-5-1c-0.3-0.1-0.4-0.5-0.2-0.7l3.8-3.4
	c0.8-0.7,1.3-1.6,1.5-2.6l1-5c0.1-0.3,0.5-0.4,0.7-0.2l3.4,3.8c0.7,0.8,1.6,1.3,2.6,1.5l5,1C86.5,78.3,86.6,78.7,86.4,78.9z" />
            <linearGradient id="grf-SVGID_13_" gradientUnits="userSpaceOnUse" x1="256.952" y1="102.6586" x2="389.5402" y2="102.6586">
              <stop offset={0} style={{stopColor: '#188BEF'}} />
              <stop offset={1} style={{stopColor: '#0944C1'}} />
            </linearGradient>
            <path className="st13" d="M323.2,38c-36.6,0-66.3,29.7-66.3,66.3c0,29.3,19,54.1,45.3,62.9c3.3,0.6,4.6-1.4,4.6-3.1
	c0-1.6-0.1-6.8-0.1-12.3c-16.7,3.1-21-4.1-22.3-7.8c-0.7-1.9-4-7.8-6.8-9.4c-2.3-1.2-5.6-4.3-0.1-4.4c5.2-0.1,8.9,4.8,10.2,6.8
	c6,10,15.5,7.2,19.3,5.5c0.6-4.3,2.3-7.2,4.2-8.9c-14.8-1.7-30.2-7.4-30.2-32.7c0-7.2,2.6-13.2,6.8-17.8c-0.7-1.7-3-8.5,0.7-17.6
	c0,0,5.6-1.7,18.2,6.8c5.3-1.5,10.9-2.2,16.6-2.2c5.6,0,11.3,0.7,16.6,2.2c12.7-8.6,18.2-6.8,18.2-6.8c3.6,9.1,1.3,15.9,0.7,17.6
	c4.2,4.6,6.8,10.5,6.8,17.8c0,25.4-15.5,31.1-30.2,32.7c2.4,2.1,4.5,6,4.5,12.3c0,8.9-0.1,16-0.1,18.2c0,1.7,1.2,3.8,4.6,3.1
	c26.2-8.8,45.2-33.6,45.2-62.9C389.5,67.7,359.9,38,323.2,38z" />
            <path className="st14" d="M14.7,63.5l-3-1.5c0,0-0.1,0-0.1-0.1l-1.5-3c-0.2-0.4-0.6-0.6-0.9-0.6s-0.8,0.2-0.9,0.6l-1.5,3
	c0,0,0,0.1-0.1,0.1l-3,1.5c-0.8,0.4-0.8,1.5,0,1.9l3,1.5c0,0,0.1,0,0.1,0.1l1.5,3c0.2,0.4,0.6,0.6,0.9,0.6c0.4,0,0.8-0.2,0.9-0.6
	l1.5-3c0,0,0-0.1,0.1-0.1l3-1.5C15.5,65,15.5,63.9,14.7,63.5z M12.5,63.8l-1.4,1.2c-0.3,0.2-0.5,0.6-0.5,0.9l-0.4,1.8
	c0,0.1-0.2,0.1-0.2,0.1l-1.2-1.4c-0.2-0.3-0.6-0.5-0.9-0.5l-1.8-0.4c-0.1,0-0.1-0.2-0.1-0.2L7.4,64c0.3-0.2,0.5-0.6,0.5-0.9l0.4-1.8
	c0-0.1,0.2-0.1,0.2-0.1l1.2,1.4c0.2,0.3,0.6,0.5,0.9,0.5l1.8,0.4C12.6,63.5,12.6,63.7,12.5,63.8z" />
            <path className="st15" d="M59.3,135.6l-4.4-2.2c-0.1,0-0.1-0.1-0.1-0.1l-2.2-4.4c-0.3-0.6-0.9-0.9-1.4-0.9c-0.6,0-1.1,0.3-1.4,0.9
	l-2.2,4.4c0,0.1-0.1,0.1-0.1,0.1l-4.4,2.2c-1.2,0.6-1.2,2.3,0,2.8l4.4,2.2c0.1,0,0.1,0.1,0.1,0.1l2.2,4.4c0.3,0.6,0.9,0.9,1.4,0.9
	c0.6,0,1.1-0.3,1.4-0.9l2.2-4.4c0-0.1,0.1-0.1,0.1-0.1l4.4-2.2C60.5,137.8,60.5,136.1,59.3,135.6z M56,135.9l-2.1,1.8
	c-0.4,0.4-0.7,0.9-0.8,1.4l-0.5,2.7c0,0.2-0.2,0.2-0.4,0.1l-1.8-2.1c-0.4-0.4-0.9-0.7-1.4-0.8l-2.7-0.5c-0.2,0-0.2-0.2-0.1-0.4
	l2.1-1.8c0.4-0.4,0.7-0.9,0.8-1.4l0.5-2.7c0-0.2,0.2-0.2,0.4-0.1l1.8,2.1c0.4,0.4,0.9,0.7,1.4,0.8l2.7,0.5
	C56,135.6,56.1,135.8,56,135.9z" />
            <linearGradient id="grf-SVGID_14_" gradientUnits="userSpaceOnUse" x1="409.2687" y1="165.3705" x2="507.5544" y2="165.3705">
              <stop offset={0} style={{stopColor: '#188BEF'}} />
              <stop offset={1} style={{stopColor: '#0944C1'}} />
            </linearGradient>
            <path className="st16" d="M507.3,170.8l-5.2-15.8c0-0.1-0.1-0.3-0.1-0.4l-10.3-31.8c-0.6-1.7-2.1-2.8-3.9-2.8h0c-1.8,0-3.4,1.1-3.9,2.9
	l-9.8,30.3h-31.1l-9.9-30.3c-0.5-1.7-2.1-2.9-3.9-2.8c-1.8,0-3.4,1.1-4,2.8l-10.3,31.9l0,0.1l-5.2,16.1c-0.8,2.4,0.1,5.1,2.1,6.6
	l45.3,32.9c0.8,0.6,2,0.6,2.8,0l0,0l45.3-32.9C507.2,175.9,508.1,173.3,507.3,170.8z M477.4,157.8H495l-27.3,35l-2.9,3.7l7.3-22.5
	L477.4,157.8z M439.5,157.8l12.6,38.7l-30.2-38.7H439.5z M487.7,125.9l8.8,27.2h-17.7L487.7,125.9z M472.4,157.8l-1.6,4.9
	l-12.4,38.2l-14-43.1H472.4z M429.1,125.9l8.9,27.2h-17.7L429.1,125.9z M414.5,173.6c-0.4-0.3-0.6-0.9-0.4-1.4l3.9-11.9l28.4,36.4
	L414.5,173.6z M502.4,173.6l-31.8,23.1l28.4-36.4l3.9,11.9C503,172.8,502.8,173.3,502.4,173.6z" />
            <g>
              <g id="Blue_3_">
                <polygon className="st17" points="359.9,273.1 377.9,273.1 382.2,247.7 355.1,247.7 		" />
                <path className="st18" d="M328.6,221.7c-1.5,0-2.8,1.2-2.8,2.7c0,0.2,0,0.3,0,0.5l11.7,71.1c0.3,1.8,1.8,3.1,3.7,3.1h56.1
			c1.4,0,2.5-1,2.8-2.3l11.7-71.9c0.2-1.5-0.8-2.9-2.3-3.2c-0.2,0-0.3,0-0.5,0L328.6,221.7z M377.9,273.1h-17.9l-4.9-25.4h27.1
			L377.9,273.1z" />
                <linearGradient id="grf-SVGID_15_" gradientUnits="userSpaceOnUse" x1="414.0677" y1="259.158" x2="371.304" y2="225.7824" gradientTransform="matrix(1 0 0 -1 0 514)">
                  <stop offset="0.18" style={{stopColor: '#0052CC'}} />
                  <stop offset={1} style={{stopColor: '#2684FF'}} />
                </linearGradient>
                <path className="st19" d="M408.1,247.7h-25.9l-4.3,25.4h-17.9l-21.2,25.1c0.7,0.6,1.5,0.9,2.4,0.9h56.2c1.4,0,2.5-1,2.8-2.3
			L408.1,247.7z" />
              </g>
            </g>
            <linearGradient id="grf-SVGID_16_" gradientUnits="userSpaceOnUse" x1="433.6454" y1="366.7925" x2="457.4807" y2="366.7925">
              <stop offset={0} style={{stopColor: '#E944FF'}} />
              <stop offset={1} style={{stopColor: '#FBB03B'}} />
            </linearGradient>
            <path className="st20" d="M456.3,364.9l-5.9-2.9c-0.1,0-0.1-0.1-0.2-0.2l-2.9-5.9c-0.4-0.8-1.1-1.2-1.9-1.2c-0.7,0-1.5,0.4-1.9,1.2
	l-2.9,5.9c0,0.1-0.1,0.1-0.2,0.2l-5.9,2.9c-1.6,0.8-1.5,3,0,3.7l5.9,2.9c0.1,0,0.1,0.1,0.2,0.2l2.9,5.9c0.4,0.8,1.1,1.2,1.9,1.2
	c0.7,0,1.5-0.4,1.9-1.2l2.9-5.9c0-0.1,0.1-0.1,0.2-0.2l5.9-2.9C457.9,367.9,457.9,365.7,456.3,364.9z M452,365.3l-2.7,2.4
	c-0.5,0.5-0.9,1.1-1.1,1.8l-0.7,3.5c0,0.2-0.3,0.3-0.5,0.1l-2.4-2.7c-0.5-0.5-1.1-0.9-1.8-1.1l-3.5-0.7c-0.2,0-0.3-0.3-0.1-0.5
	l2.7-2.4c0.5-0.5,0.9-1.1,1.1-1.8l0.7-3.5c0-0.2,0.3-0.3,0.5-0.1l2.4,2.7c0.5,0.5,1.1,0.9,1.8,1.1l3.5,0.7
	C452.1,364.9,452.1,365.2,452,365.3z" />
            <path className="st21" d="M504.6,345.5l-1.9-0.9c0,0,0,0-0.1-0.1l-0.9-1.9c-0.1-0.2-0.4-0.4-0.6-0.4c-0.2,0-0.5,0.1-0.6,0.4l-0.9,1.9
	c0,0,0,0-0.1,0.1l-1.9,0.9c-0.5,0.2-0.5,0.9,0,1.2l1.9,0.9c0,0,0,0,0.1,0.1l0.9,1.9c0.1,0.2,0.4,0.4,0.6,0.4c0.2,0,0.5-0.1,0.6-0.4
	l0.9-1.9c0,0,0,0,0.1-0.1l1.9-0.9C505.1,346.5,505.1,345.7,504.6,345.5z M503.2,345.6l-0.9,0.8c-0.2,0.2-0.3,0.4-0.3,0.6l-0.2,1.1
	c0,0.1-0.1,0.1-0.2,0l-0.8-0.9c-0.2-0.2-0.4-0.3-0.6-0.3l-1.1-0.2c-0.1,0-0.1-0.1,0-0.2l0.9-0.8c0.2-0.2,0.3-0.4,0.3-0.6l0.2-1.1
	c0-0.1,0.1-0.1,0.2,0l0.8,0.9c0.2,0.2,0.4,0.3,0.6,0.3l1.1,0.2C503.3,345.5,503.3,345.6,503.2,345.6z" />
            <linearGradient id="grf-SVGID_17_" gradientUnits="userSpaceOnUse" x1="438.1353" y1="260.4049" x2="491.426" y2="260.4049">
              <stop offset={0} style={{stopColor: '#E944FF'}} />
              <stop offset={1} style={{stopColor: '#33CCFF'}} />
            </linearGradient>
            <path className="st22" d="M488.8,256.2l-13.1-6.4c-0.2-0.1-0.3-0.2-0.4-0.4l-6.4-13.1c-0.9-1.7-2.5-2.6-4.2-2.6s-3.3,0.9-4.2,2.6
	l-6.4,13.1c-0.1,0.2-0.2,0.3-0.4,0.4l-13.1,6.4c-3.5,1.7-3.5,6.6,0,8.4l13.1,6.4c0.2,0.1,0.3,0.2,0.4,0.4l6.4,13.1
	c0.9,1.7,2.5,2.6,4.2,2.6c1.7,0,3.3-0.9,4.2-2.6l6.4-13.1c0.1-0.2,0.2-0.3,0.4-0.4l13.1-6.4C492.3,262.9,492.3,257.9,488.8,256.2z
	 M479.1,257.1l-6.1,5.3c-1.2,1.1-2,2.5-2.4,4.1l-1.6,7.9c-0.1,0.5-0.7,0.7-1.1,0.3l-5.3-6.1c-1.1-1.2-2.5-2-4.1-2.4l-7.9-1.6
	c-0.5-0.1-0.7-0.7-0.3-1.1l6.1-5.3c1.2-1.1,2-2.5,2.4-4.1l1.6-7.9c0.1-0.5,0.7-0.7,1.1-0.3l5.3,6.1c1.1,1.2,2.5,2,4.1,2.4l7.9,1.6
	C479.3,256.2,479.5,256.8,479.1,257.1z" />
          </svg>
        </div>
        <div className="feature-content">
          <h3>Индивидуальный подход</h3>
          <p className="feature-description">
          Каждый наш клиент должен чувствовать, что он или она является самым важным человеком в мире для нас.
          Каждый наш проект является уникальным маркетинговым инструментом с эксклюзивным дизайном. 
          Наши проекты должны быть лучше, чем у других. Вместо реализации «как у конкурента» предпочитаем работать правильно, без повтора чужих ошибок.
					
										
          </p>
        </div>
      </div>

      <div className="feature animate-in configuration visible">
        <div className="feature-illustration">{/*?xml version="1.0" encoding="utf-8"?*/}
          <svg version="1.1" id="ecf" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{enableBackground: 'new 0 0 512 512'}} xmlSpace="preserve">
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\t#ecf .st0{fill:url(#ecf-SVGID_1_);}\n\t#ecf .st1{fill:url(#ecf-SVGID_2_);}\n\t#ecf .st2{fill:#33CCFF;}\n\t#ecf .st3{fill:#EC0C43;}\n\t#ecf .st4{fill:url(#ecf-SVGID_3_);}\n\t#ecf .st5{fill:url(#ecf-SVGID_4_);}\n\t#ecf .st6{fill:#E944FF;}\n\t#ecf .st7{fill:#FBB03B;}\n\t#ecf .st8{fill:#0644C1;}\n\t#ecf .st9{fill:#0957FF;}\n\t#ecf .st10{fill:url(#ecf-SVGID_5_);}\n\t#ecf .st11{fill:url(#ecf-SVGID_6_);}\n\t#ecf .st12{fill:url(#ecf-SVGID_7_);}\n\t#ecf .st13{fill:none;}\n\t#ecf .st14{fill:url(#ecf-SVGID_8_);}\n\t#ecf .st15{fill:url(#ecf-SVGID_9_);}\n\t#ecf .st16{fill:url(#ecf-SVGID_10_);}\n" }} />
            <linearGradient id="ecf-SVGID_1_" gradientUnits="userSpaceOnUse" x1={6} y1="324.306" x2="378.9651" y2="324.306">
              <stop offset={0} style={{stopColor: '#FBB03B'}} />
              <stop offset={1} style={{stopColor: '#E944FF'}} />
            </linearGradient>
            <path className="st0" d="M34.7,208.4c84.1-45.5,138.5,4.5,214.5,75.9s140.1,72.6,128.4,109.1c-20.4,64.1-197.4,114.5-344-24.4
	C6.5,343.3-12.2,233.7,34.7,208.4z" />
            <linearGradient id="ecf-SVGID_2_" gradientUnits="userSpaceOnUse" x1="385.8645" y1="65.5046" x2="434.8082" y2="65.5046">
              <stop offset={0} style={{stopColor: '#FBB03B'}} />
              <stop offset={1} style={{stopColor: '#E944FF'}} />
            </linearGradient>
            <path className="st1" d="M432.4,61.7l-12-5.9c-0.1-0.1-0.3-0.2-0.3-0.3l-5.9-12c-0.8-1.6-2.3-2.4-3.8-2.4c-1.5,0-3.1,0.8-3.8,2.4
	l-5.9,12c-0.1,0.1-0.2,0.3-0.3,0.3l-12,5.9c-3.2,1.6-3.2,6.1,0,7.7l12,5.9c0.1,0.1,0.3,0.2,0.3,0.3l5.9,12c0.8,1.6,2.3,2.4,3.8,2.4
	c1.5,0,3.1-0.8,3.8-2.4l5.9-12c0.1-0.1,0.2-0.3,0.3-0.3l12-5.9C435.6,67.8,435.6,63.2,432.4,61.7z M423.5,62.5l-5.6,4.9
	c-1.1,1-1.9,2.3-2.2,3.8l-1.4,7.3c-0.1,0.5-0.7,0.6-1,0.3l-4.9-5.6c-1-1.1-2.3-1.9-3.8-2.2l-7.3-1.4c-0.5-0.1-0.6-0.7-0.3-1l5.6-4.9
	c1.1-1,1.9-2.3,2.2-3.8l1.4-7.3c0.1-0.5,0.7-0.6,1-0.3l4.9,5.6c1,1.1,2.3,1.9,3.8,2.2l7.3,1.4C423.7,61.6,423.8,62.2,423.5,62.5z" />
            <path className="st2" d="M458.6,95l-2.9-1.4c0,0-0.1,0-0.1-0.1l-1.4-2.9c-0.2-0.4-0.6-0.6-0.9-0.6s-0.7,0.2-0.9,0.6l-1.4,2.9
	c0,0,0,0.1-0.1,0.1l-2.9,1.4c-0.8,0.4-0.8,1.5,0,1.9l2.9,1.4c0,0,0.1,0,0.1,0.1l1.4,2.9c0.2,0.4,0.6,0.6,0.9,0.6
	c0.4,0,0.7-0.2,0.9-0.6l1.4-2.9c0,0,0-0.1,0.1-0.1l2.9-1.4C459.3,96.5,459.3,95.4,458.6,95z M456.4,95.2l-1.4,1.2
	c-0.3,0.2-0.5,0.6-0.5,0.9l-0.3,1.8c0,0.1-0.2,0.1-0.2,0.1l-1.2-1.4c-0.2-0.3-0.6-0.5-0.9-0.5l-1.8-0.3c-0.1,0-0.1-0.2-0.1-0.2
	l1.4-1.2c0.3-0.2,0.5-0.6,0.5-0.9l0.3-1.8c0-0.1,0.2-0.1,0.2-0.1l1.2,1.4c0.2,0.3,0.6,0.5,0.9,0.5l1.8,0.3
	C456.4,95,456.5,95.1,456.4,95.2z" />
            <path className="st3" d="M337.3,218l-2.9-1.4c0,0-0.1,0-0.1-0.1l-1.4-2.9c-0.2-0.4-0.6-0.6-0.9-0.6c-0.4,0-0.7,0.2-0.9,0.6l-1.4,2.9
	c0,0,0,0.1-0.1,0.1l-2.9,1.4c-0.8,0.4-0.8,1.5,0,1.9l2.9,1.4c0,0,0.1,0,0.1,0.1l1.4,2.9c0.2,0.4,0.6,0.6,0.9,0.6
	c0.4,0,0.7-0.2,0.9-0.6l1.4-2.9c0,0,0-0.1,0.1-0.1l2.9-1.4C338,219.4,338,218.3,337.3,218z M335.1,218.2l-1.4,1.2
	c-0.3,0.2-0.5,0.6-0.5,0.9l-0.3,1.8c0,0.1-0.2,0.1-0.2,0.1l-1.2-1.4c-0.2-0.3-0.6-0.5-0.9-0.5l-1.8-0.3c-0.1,0-0.1-0.2-0.1-0.2
	l1.4-1.2c0.3-0.2,0.5-0.6,0.5-0.9l0.3-1.8c0-0.1,0.2-0.1,0.2-0.1l1.2,1.4c0.2,0.3,0.6,0.5,0.9,0.5l1.8,0.3
	C335.1,217.9,335.2,218.1,335.1,218.2z" />
            <linearGradient id="ecf-SVGID_3_" gradientUnits="userSpaceOnUse" x1="346.8149" y1="263.3219" x2="354.0954" y2="263.3219">
              <stop offset={0} style={{stopColor: '#FDF53F'}} />
              <stop offset={1} style={{stopColor: '#D93C65'}} />
            </linearGradient>
            <path className="st4" d="M353.7,262.7l-1.8-0.9c0,0,0,0,0,0L351,260c-0.1-0.2-0.3-0.4-0.6-0.4c-0.2,0-0.5,0.1-0.6,0.4l-0.9,1.8
	c0,0,0,0,0,0l-1.8,0.9c-0.5,0.2-0.5,0.9,0,1.1l1.8,0.9c0,0,0,0,0,0l0.9,1.8c0.1,0.2,0.3,0.4,0.6,0.4c0.2,0,0.5-0.1,0.6-0.4l0.9-1.8
	c0,0,0,0,0,0l1.8-0.9C354.2,263.7,354.2,263,353.7,262.7z M352.4,262.9l-0.8,0.7c-0.2,0.1-0.3,0.3-0.3,0.6l-0.2,1.1
	c0,0.1-0.1,0.1-0.1,0l-0.7-0.8c-0.1-0.2-0.3-0.3-0.6-0.3l-1.1-0.2c-0.1,0-0.1-0.1,0-0.1l0.8-0.7c0.2-0.1,0.3-0.3,0.3-0.6l0.2-1.1
	c0-0.1,0.1-0.1,0.1,0l0.7,0.8c0.1,0.2,0.3,0.3,0.6,0.3l1.1,0.2C352.4,262.7,352.5,262.8,352.4,262.9z" />
            <linearGradient id="ecf-SVGID_4_" gradientUnits="userSpaceOnUse" x1="340.7923" y1="227.6554" x2="373.003" y2="227.6554">
              <stop offset={0} style={{stopColor: '#E944FF'}} />
              <stop offset={1} style={{stopColor: '#33CCFF'}} />
            </linearGradient>
            <path className="st5" d="M371.4,225.1l-7.9-3.9c-0.1,0-0.2-0.1-0.2-0.2l-3.9-7.9c-0.5-1-1.5-1.6-2.5-1.6c-1,0-2,0.5-2.5,1.6l-3.9,7.9
	c0,0.1-0.1,0.2-0.2,0.2l-7.9,3.9c-2.1,1-2.1,4,0,5l7.9,3.9c0.1,0,0.2,0.1,0.2,0.2l3.9,7.9c0.5,1,1.5,1.6,2.5,1.6c1,0,2-0.5,2.5-1.6
	l3.9-7.9c0-0.1,0.1-0.2,0.2-0.2l7.9-3.9C373.5,229.1,373.5,226.2,371.4,225.1z M365.5,225.7l-3.7,3.2c-0.7,0.6-1.2,1.5-1.4,2.5
	l-0.9,4.8c-0.1,0.3-0.4,0.4-0.6,0.2l-3.2-3.7c-0.6-0.7-1.5-1.2-2.5-1.4l-4.8-0.9c-0.3-0.1-0.4-0.4-0.2-0.6l3.7-3.2
	c0.7-0.6,1.2-1.5,1.4-2.5l0.9-4.8c0.1-0.3,0.4-0.4,0.6-0.2l3.2,3.7c0.6,0.7,1.5,1.2,2.5,1.4l4.8,0.9
	C365.7,225.1,365.8,225.5,365.5,225.7z" />
            <path className="st6" d="M442.9,141.9l-4.4-2.2c-0.1,0-0.1-0.1-0.1-0.1l-2.2-4.4c-0.3-0.6-0.8-0.9-1.4-0.9c-0.6,0-1.1,0.3-1.4,0.9
	l-2.2,4.4c0,0.1-0.1,0.1-0.1,0.1l-4.4,2.2c-1.2,0.6-1.2,2.2,0,2.8l4.4,2.2c0.1,0,0.1,0.1,0.1,0.1l2.2,4.4c0.3,0.6,0.8,0.9,1.4,0.9
	c0.6,0,1.1-0.3,1.4-0.9l2.2-4.4c0-0.1,0.1-0.1,0.1-0.1l4.4-2.2C444.1,144.2,444.1,142.5,442.9,141.9z M439.6,142.3l-2,1.8
	c-0.4,0.4-0.7,0.8-0.8,1.4l-0.5,2.7c0,0.2-0.2,0.2-0.4,0.1l-1.8-2c-0.4-0.4-0.8-0.7-1.4-0.8l-2.7-0.5c-0.2,0-0.2-0.2-0.1-0.4l2-1.8
	c0.4-0.4,0.7-0.8,0.8-1.4l0.5-2.7c0-0.2,0.2-0.2,0.4-0.1l1.8,2c0.4,0.4,0.8,0.7,1.4,0.8l2.7,0.5
	C439.7,141.9,439.8,142.1,439.6,142.3z" />
            <path className="st7" d="M508.5,326.7l-1.8-0.9c0,0,0,0-0.1,0l-0.9-1.8c-0.1-0.2-0.4-0.4-0.6-0.4c-0.2,0-0.5,0.1-0.6,0.4l-0.9,1.8
	c0,0,0,0,0,0.1l-1.8,0.9c-0.5,0.2-0.5,0.9,0,1.2l1.8,0.9c0,0,0,0,0.1,0l0.9,1.8c0.1,0.2,0.4,0.4,0.6,0.4c0.2,0,0.5-0.1,0.6-0.4
	l0.9-1.8c0,0,0,0,0-0.1l1.8-0.9C509,327.7,509,327,508.5,326.7z M507.1,326.9l-0.9,0.7c-0.2,0.1-0.3,0.4-0.3,0.6l-0.2,1.1
	c0,0.1-0.1,0.1-0.1,0l-0.7-0.9c-0.1-0.2-0.4-0.3-0.6-0.3l-1.1-0.2c-0.1,0-0.1-0.1,0-0.1l0.9-0.7c0.2-0.1,0.3-0.4,0.3-0.6l0.2-1.1
	c0-0.1,0.1-0.1,0.1,0l0.7,0.9c0.1,0.2,0.4,0.3,0.6,0.3l1.1,0.2C507.2,326.7,507.2,326.8,507.1,326.9z" />
            <g>
              <path className="st8" d="M201,455.8c-6.4-17.9-13-37.6-12.8-42.2c0.4-6.3,10.2-23,10.2-23l-43.6-80.1l35.9-49.5l29.4,118.6l5.1,78.4" />
              <path className="st9" d="M89.2,412.4c6.8-17.8,13.6-34,17.4-37.2c6.2-5.4,23.8-14.7,23.8-14.7s-4.7-58.3,0.7-73.7
		c5.4-15.4,22.9-30.8,22.9-30.8l37.8,0.3c-9.1,41.9-23.9,89.2-35.6,107.7c-9.5,15.1-26.2,40.2-41.3,62.8" />
              <linearGradient id="ecf-SVGID_5_" gradientUnits="userSpaceOnUse" x1="-371.6502" y1="-172.6724" x2="-267.0361" y2="-159.3317" gradientTransform="matrix(0.992 -0.1265 0.1265 0.992 485.0799 339.4686)">
                <stop offset={0} style={{stopColor: '#E944FF'}} />
                <stop offset={1} style={{stopColor: '#33CCFF'}} />
              </linearGradient>
              <path className="st10" d="M106.6,276c-2-4.3-14.2-25.8-11.7-39.6c0,0,1.1-72.1,37.9-80.7c22.3-5.2,57.3,4.3,65.8,29.5
		c3.6,10.8,0.1,39.5-6.7,71.1l-37.8-0.3l-13.7-54c0,0-3,9.3-12,17.3c-9,8-12.1,11-12.1,11s4,21,7,34.9L106.6,276z" />
              <linearGradient id="ecf-SVGID_6_" gradientUnits="userSpaceOnUse" x1="-304.8387" y1="-223.4318" x2="-275.5524" y2="-223.4318" gradientTransform="matrix(0.992 -0.1265 0.1265 0.992 485.0799 339.4686)">
                <stop offset={0} style={{stopColor: '#FDF53F'}} />
                <stop offset={1} style={{stopColor: '#D93C65'}} />
              </linearGradient>
              <path className="st11" d="M185.3,167.1c0,0-5.4-14.7-4.3-20.7c1.1-5.9,0.6-8.5,0.6-8.5l-21.8,2.8l-5.6,14.2
		C154.2,154.9,169.8,172.2,185.3,167.1z" />
              <linearGradient id="ecf-SVGID_7_" gradientUnits="userSpaceOnUse" x1="-317.7519" y1="-263.217" x2="-265.7945" y2="-263.217" gradientTransform="matrix(0.992 -0.1265 0.1265 0.992 485.0799 339.4686)">
                <stop offset={0} style={{stopColor: '#FDF53F'}} />
                <stop offset={1} style={{stopColor: '#D93C65'}} />
              </linearGradient>
              <path className="st12" d="M149.4,87.9c12.2-6.2,23.3-8.8,34,21.7s7.9,28.7,6,30.5c-1.9,1.8-19.4,13-37.6-6.2S126.7,99.5,149.4,87.9z" />
              <path className="st3" d="M192.9,130.1c-0.6-2.2-1.3-4.4-2.6-6.3c-1.3-1.9-3.3-3.4-5.5-3.6c-3.1-0.3-6.3,2.2-6.7,5.3
		c-0.4,3.3,1.8,6.5,1.2,9.7c-7.4,0.5-14.7-2.5-21-6.4c-2.4-1.5-4.8-3.2-6-5.7c-1.2-2.5-0.9-6,1.5-7.5c3.1-1.9,7,0.4,9.8,2.7
		c-1.1-5.8-2.1-12.3,1.2-17.1c2-2.9,5-4.4,8.2-5.5c2.2-0.8,4.7-1,5.4-3.6c0.9-3-0.8-8.2-1.4-11.2c-0.9-4.1-2.1-8.1-3.9-11.9
		c-0.9-1.9-2.1-3.8-4-4.6c-1.2-0.5-2.6-0.6-3.9-0.5c-7.8,0.6-14.9,6.9-16.5,14.6c-1.8-2.3-5.7-1.9-7.7,0.3c-2,2.1-2.4,5.3-2.2,8.2
		c-4.9-2-11-0.2-14.1,4.1c-1,1.4-1.7,3-1.6,4.7c0.1,1.7,1,3.4,2.5,4.1c-3.7,0.9-7.8,2.4-8.9,6c-0.9,3.1,1.1,6.4,3.6,8.5
		s5.7,3.2,8.4,5.1c5.5,3.8,8.6,10.1,12.8,15.4c6.4,7.9,15.7,13.5,25.7,15.4c2.3,0.4,4.6,0.7,6.9,0.5c4.2-0.3,8.1-2.1,12-3.8
		c2-0.9,4.1-1.8,5.5-3.5C194.8,140,194.1,134.6,192.9,130.1z M187.8,131.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.3-0.6,0.4-0.9,0.5
		c-1,0.4-4.5,2.1-5.4,1.2c-0.6-0.6,0.3-4,0.1-5c-0.1-0.4-0.1-0.8,0.1-1.1c0.1-0.2,0.3-0.3,0.5-0.5c1.1-0.8,2.3-1.5,3.6-2
		c0.2-0.1,0.4-0.1,0.5-0.1c0.2,0.1,0.3,0.2,0.4,0.4C187.6,127.1,188,129.1,187.8,131.1z" />
              <g>
                <path className="st13" d="M32.6,359.1c-5.5,4.6-6.3,12.7-1.7,18.2c4.6,5.5,12.7,6.3,18.2,1.7c5.5-4.6,6.3-12.7,1.7-18.2
			C46.3,355.3,38.1,354.5,32.6,359.1z" />
                <linearGradient id="ecf-SVGID_8_" gradientUnits="userSpaceOnUse" x1="-50.4589" y1="-211.3182" x2="-497.7079" y2="-83.5902" gradientTransform="matrix(0.992 -0.1265 0.1265 0.992 485.0799 339.4686)">
                  <stop offset={0} style={{stopColor: '#0944C1'}} />
                  <stop offset={1} style={{stopColor: '#188BEF'}} />
                </linearGradient>
                <path className="st14" d="M390.2,110.9L346,147.5l-26-9.7l-4.6-27.4l44.2-36.6c3.1-2.6,2.2-7.6-1.6-8.9c-19.2-6.4-41.1-3.1-57.8,10.8
			c-20.8,17.2-27.1,45.4-17.6,69.2l-264,204c-13.1,10.2-16.1,29.2-5.9,42.3c10.6,13.6,30.4,15.4,43.3,4L306,173.4
			c21.6,13.8,50.5,12.8,71.3-4.4c16.7-13.8,24.1-34.8,21.4-54.8C398.1,110.2,393.3,108.3,390.2,110.9z M49.1,379
			c-5.5,4.6-13.6,3.8-18.2-1.7c-4.6-5.5-3.8-13.6,1.7-18.2c5.5-4.6,13.6-3.8,18.2,1.7C55.4,366.3,54.6,374.4,49.1,379z" />
              </g>
              <linearGradient id="ecf-SVGID_9_" gradientUnits="userSpaceOnUse" x1="-367.4136" y1="-89.3332" x2="-320.031" y2="-89.3332" gradientTransform="matrix(0.992 -0.1265 0.1265 0.992 485.0799 339.4686)">
                <stop offset={0} style={{stopColor: '#FDF53F'}} />
                <stop offset={1} style={{stopColor: '#D93C65'}} />
              </linearGradient>
              <path className="st15" d="M123.2,265.3c0.6,2.7,1.1,5.1,1.6,7.1c2,8.5,3.5,16,3.5,16s10.7,13.9,14.2,15.5c3.5,1.7,14,2.9,15.8,6
		c1.8,3.1-1.5,9.1-6.4,11.3c-5,2.2-12.7,2.9-17.8-3.3c-5-6.2-15.8-18.5-16.8-23.3c-1-4.8-8.8-14.4-10.8-18.7L123.2,265.3z" />
              <linearGradient id="ecf-SVGID_10_" gradientUnits="userSpaceOnUse" x1="-260.1855" y1="-124.6106" x2="-238.9492" y2="-124.6106" gradientTransform="matrix(0.992 -0.1265 0.1265 0.992 485.0799 339.4686)">
                <stop offset={0} style={{stopColor: '#FDF53F'}} />
                <stop offset={1} style={{stopColor: '#D93C65'}} />
              </linearGradient>
              <path className="st16" d="M211.7,252.2c0.5,6.5,13.1,1.7,16.2-2.1c3.1-3.7,5.8-8.9,2.3-10.8S211.4,249,211.7,252.2z" />
            </g>
          </svg>
        </div>
        <div className="feature-content">
          <h3>Делаем хорошо, как для себя</h3>
          <p className="feature-description">
            Мы подходим к работе с любовью и ответственностью, что гарантирует отличный результат в успехе продаж и расширении базы клиентов компании. Во всех проектах мы внедряем индивидуально разработанные инновационные системы и технологии, быстрые при загрузке и простые в управлении.
          </p>
        </div>
      </div>

      <div className="feature animate-in dev-portal visible">
        <div className="feature-illustration">{/*?xml version="1.0" encoding="utf-8"?*/}
          <svg version="1.1" id="dpf" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{enableBackground: 'new 0 0 512 512'}} xmlSpace="preserve">
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\t#dpf .st0{fill:url(#dpf-SVGID_1_);}\n\t#dpf .st1{fill:url(#dpf-SVGID_2_);}\n\t#dpf .st2{fill:#33CCFF;}\n\t#dpf .st3{fill:#EC0C43;}\n\t#dpf .st4{fill:url(#dpf-SVGID_3_);}\n\t#dpf .st5{fill:url(#dpf-SVGID_4_);}\n\t#dpf .st6{fill:#E944FF;}\n\t#dpf .st7{fill:#FBB03B;}\n\t#dpf .st8{fill:url(#dpf-SVGID_5_);}\n\t#dpf .st9{fill:url(#dpf-SVGID_6_);}\n\t#dpf .st10{fill:url(#dpf-SVGID_7_);}\n\t#dpf .st11{fill:url(#dpf-SVGID_8_);}\n\t#dpf .st12{fill:url(#dpf-SVGID_9_);}\n\t#dpf .st13{fill:url(#dpf-SVGID_10_);}\n\t#dpf .st14{fill:url(#dpf-SVGID_11_);}\n" }} />
            <linearGradient id="dpf-SVGID_1_" gradientUnits="userSpaceOnUse" x1="8.377" y1="360.0555" x2={425} y2="360.0555">
              <stop offset={0} style={{stopColor: '#0944C1'}} />
              <stop offset={1} style={{stopColor: '#188BEF'}} />
            </linearGradient>
            <path className="st0" d="M17.5,288.4c36.9-91.2,175.2-90.3,278.5-48.2c109.7,44.7,150.7,138.6,118,198.3c-58.7,107.3-134.9,73-230.8,7.2
	C116.6,399.9-29.5,404.7,17.5,288.4z" />
            <linearGradient id="dpf-SVGID_2_" gradientUnits="userSpaceOnUse" x1="256.68" y1="32.8065" x2="312.2927" y2="32.8065">
              <stop offset={0} style={{stopColor: '#E944FF'}} />
              <stop offset={1} style={{stopColor: '#33CCFF'}} />
            </linearGradient>
            <path className="st1" d="M309.6,28.4l-13.7-6.7c-0.2-0.1-0.3-0.2-0.4-0.4l-6.7-13.7c-0.9-1.8-2.6-2.7-4.4-2.7c-1.7,0-3.5,0.9-4.4,2.7
	l-6.7,13.7c-0.1,0.2-0.2,0.3-0.4,0.4l-13.7,6.7c-3.6,1.8-3.6,6.9,0,8.7l13.7,6.7c0.2,0.1,0.3,0.2,0.4,0.4l6.7,13.7
	c0.9,1.8,2.6,2.7,4.4,2.7c1.7,0,3.5-0.9,4.4-2.7l6.7-13.7c0.1-0.2,0.2-0.3,0.4-0.4l13.7-6.7C313.2,35.4,313.2,30.2,309.6,28.4z
	 M299.4,29.4l-6.3,5.5c-1.3,1.1-2.1,2.6-2.5,4.3l-1.6,8.2c-0.1,0.5-0.8,0.7-1.1,0.3l-5.5-6.3c-1.1-1.3-2.6-2.1-4.3-2.5l-8.2-1.6
	c-0.5-0.1-0.7-0.8-0.3-1.1l6.3-5.5c1.3-1.1,2.1-2.6,2.5-4.3l1.6-8.2c0.1-0.5,0.8-0.7,1.1-0.3l5.5,6.3c1.1,1.3,2.6,2.1,4.3,2.5
	l8.2,1.6C299.6,28.4,299.8,29.1,299.4,29.4z" />
            <path className="st2" d="M312.5,211.6l-3.3-1.6c0,0-0.1-0.1-0.1-0.1l-1.6-3.3c-0.2-0.4-0.6-0.7-1.1-0.7c-0.4,0-0.8,0.2-1.1,0.7l-1.6,3.3
	c0,0-0.1,0.1-0.1,0.1l-3.3,1.6c-0.9,0.4-0.9,1.7,0,2.1l3.3,1.6c0,0,0.1,0.1,0.1,0.1l1.6,3.3c0.2,0.4,0.6,0.7,1.1,0.7
	c0.4,0,0.8-0.2,1.1-0.7l1.6-3.3c0,0,0.1-0.1,0.1-0.1l3.3-1.6C313.4,213.3,313.4,212.1,312.5,211.6z M310,211.9l-1.5,1.3
	c-0.3,0.3-0.5,0.6-0.6,1l-0.4,2c0,0.1-0.2,0.2-0.3,0.1l-1.3-1.5c-0.3-0.3-0.6-0.5-1-0.6l-2-0.4c-0.1,0-0.2-0.2-0.1-0.3l1.5-1.3
	c0.3-0.3,0.5-0.6,0.6-1l0.4-2c0-0.1,0.2-0.2,0.3-0.1l1.3,1.5c0.3,0.3,0.6,0.5,1,0.6l2,0.4C310.1,211.6,310.1,211.8,310,211.9z" />
            <path className="st3" d="M332.7,165.5l-3.3-1.6c0,0-0.1-0.1-0.1-0.1l-1.6-3.3c-0.2-0.4-0.6-0.7-1.1-0.7s-0.8,0.2-1.1,0.7l-1.6,3.3
	c0,0-0.1,0.1-0.1,0.1l-3.3,1.6c-0.9,0.4-0.9,1.7,0,2.1l3.3,1.6c0,0,0.1,0.1,0.1,0.1l1.6,3.3c0.2,0.4,0.6,0.7,1.1,0.7
	c0.4,0,0.8-0.2,1.1-0.7l1.6-3.3c0,0,0.1-0.1,0.1-0.1l3.3-1.6C333.6,167.2,333.6,165.9,332.7,165.5z M330.2,165.7l-1.5,1.3
	c-0.3,0.3-0.5,0.6-0.6,1l-0.4,2c0,0.1-0.2,0.2-0.3,0.1l-1.3-1.5c-0.3-0.3-0.6-0.5-1-0.6l-2-0.4c-0.1,0-0.2-0.2-0.1-0.3l1.5-1.3
	c0.3-0.3,0.5-0.6,0.6-1l0.4-2c0-0.1,0.2-0.2,0.3-0.1l1.3,1.5c0.3,0.3,0.6,0.5,1,0.6l2,0.4C330.3,165.5,330.3,165.7,330.2,165.7z" />
            <linearGradient id="dpf-SVGID_3_" gradientUnits="userSpaceOnUse" x1="361.4126" y1="205.4141" x2="369.6851" y2="205.4141">
              <stop offset={0} style={{stopColor: '#FDF53F'}} />
              <stop offset={1} style={{stopColor: '#D93C65'}} />
            </linearGradient>
            <path className="st4" d="M369.3,204.8l-2-1c0,0,0,0-0.1-0.1l-1-2c-0.1-0.3-0.4-0.4-0.6-0.4s-0.5,0.1-0.6,0.4l-1,2c0,0,0,0-0.1,0.1l-2,1
	c-0.5,0.3-0.5,1,0,1.3l2,1c0,0,0,0,0.1,0.1l1,2c0.1,0.3,0.4,0.4,0.6,0.4c0.3,0,0.5-0.1,0.6-0.4l1-2c0,0,0,0,0.1-0.1l2-1
	C369.8,205.8,369.8,205,369.3,204.8z M367.8,204.9l-0.9,0.8c-0.2,0.2-0.3,0.4-0.4,0.6l-0.2,1.2c0,0.1-0.1,0.1-0.2,0l-0.8-0.9
	c-0.2-0.2-0.4-0.3-0.6-0.4l-1.2-0.2c-0.1,0-0.1-0.1,0-0.2l0.9-0.8c0.2-0.2,0.3-0.4,0.4-0.6l0.2-1.2c0-0.1,0.1-0.1,0.2,0l0.8,0.9
	c0.2,0.2,0.4,0.3,0.6,0.4l1.2,0.2C367.8,204.8,367.8,204.9,367.8,204.9z" />
            <linearGradient id="dpf-SVGID_4_" gradientUnits="userSpaceOnUse" x1="299.623" y1="102.1481" x2="336.2227" y2="102.1481">
              <stop offset={0} style={{stopColor: '#FBB03B'}} />
              <stop offset={1} style={{stopColor: '#E944FF'}} />
            </linearGradient>
            <path className="st5" d="M334.4,99.3l-9-4.4c-0.1-0.1-0.2-0.1-0.2-0.2l-4.4-9c-0.6-1.2-1.7-1.8-2.9-1.8c-1.1,0-2.3,0.6-2.9,1.8l-4.4,9
	c-0.1,0.1-0.1,0.2-0.2,0.2l-9,4.4c-2.4,1.2-2.4,4.6,0,5.7l9,4.4c0.1,0.1,0.2,0.1,0.2,0.2l4.4,9c0.6,1.2,1.7,1.8,2.9,1.8
	c1.1,0,2.3-0.6,2.9-1.8l4.4-9c0.1-0.1,0.1-0.2,0.2-0.2l9-4.4C336.8,103.8,336.8,100.4,334.4,99.3z M327.7,99.9l-4.2,3.6
	c-0.8,0.7-1.4,1.7-1.6,2.8l-1.1,5.4c-0.1,0.3-0.5,0.5-0.7,0.2l-3.6-4.2c-0.7-0.8-1.7-1.4-2.8-1.6l-5.4-1.1c-0.3-0.1-0.5-0.5-0.2-0.7
	l4.2-3.6c0.8-0.7,1.4-1.7,1.6-2.8l1.1-5.4c0.1-0.3,0.5-0.5,0.7-0.2l3.6,4.2c0.7,0.8,1.7,1.4,2.8,1.6l5.4,1.1
	C327.9,99.3,328,99.7,327.7,99.9z" />
            <path className="st6" d="M384.9,31.2l-5-2.5c-0.1,0-0.1-0.1-0.1-0.1l-2.5-5c-0.3-0.7-1-1-1.6-1c-0.6,0-1.3,0.3-1.6,1l-2.5,5
	c0,0.1-0.1,0.1-0.1,0.1l-5,2.5c-1.3,0.7-1.3,2.5,0,3.2l5,2.5c0.1,0,0.1,0.1,0.1,0.1l2.5,5c0.3,0.7,1,1,1.6,1c0.6,0,1.3-0.3,1.6-1
	l2.5-5c0-0.1,0.1-0.1,0.1-0.1l5-2.5C386.3,33.7,386.3,31.9,384.9,31.2z M381.2,31.6l-2.3,2c-0.5,0.4-0.8,1-0.9,1.6l-0.6,3
	c0,0.2-0.3,0.3-0.4,0.1l-2-2.3c-0.4-0.5-1-0.8-1.6-0.9l-3-0.6c-0.2,0-0.3-0.3-0.1-0.4l2.3-2c0.5-0.4,0.8-1,0.9-1.6l0.6-3
	c0-0.2,0.3-0.3,0.4-0.1l2,2.3c0.4,0.5,1,0.8,1.6,0.9l3,0.6C381.3,31.2,381.4,31.4,381.2,31.6z" />
            <path className="st7" d="M277,130.1l-2.1-1c0,0,0,0-0.1-0.1l-1-2.1c-0.1-0.3-0.4-0.4-0.7-0.4s-0.5,0.1-0.7,0.4l-1,2.1c0,0,0,0-0.1,0.1
	l-2.1,1c-0.6,0.3-0.6,1.1,0,1.3l2.1,1c0,0,0,0,0.1,0.1l1,2.1c0.1,0.3,0.4,0.4,0.7,0.4c0.3,0,0.5-0.1,0.7-0.4l1-2.1c0,0,0,0,0.1-0.1
	l2.1-1C277.5,131.2,277.5,130.4,277,130.1z M275.4,130.2l-1,0.9c-0.2,0.2-0.3,0.4-0.4,0.7l-0.3,1.3c0,0.1-0.1,0.1-0.2,0l-0.9-1
	c-0.2-0.2-0.4-0.3-0.7-0.4l-1.3-0.3c-0.1,0-0.1-0.1,0-0.2l1-0.9c0.2-0.2,0.3-0.4,0.4-0.7l0.3-1.3c0-0.1,0.1-0.1,0.2,0l0.9,1
	c0.2,0.2,0.4,0.3,0.7,0.4l1.3,0.3C275.4,130.1,275.5,130.2,275.4,130.2z" />
            <g>
              <g>
                <linearGradient id="dpf-SVGID_5_" gradientUnits="userSpaceOnUse" x1="119.1363" y1="109.9683" x2="146.9734" y2="109.9683">
                  <stop offset={0} style={{stopColor: '#D93C65'}} />
                  <stop offset={1} style={{stopColor: '#FDF53F'}} />
                </linearGradient>
                <path className="st8" d="M119.1,95.6c0,0,4.4,13.8,4.3,17.4s-0.9,6.5-0.9,6.5s5.5,5.6,12.9,4.7c7.4-0.9,11.6-8.8,11.6-8.8l-6.1-19.8
			H119.1z" />
                <linearGradient id="dpf-SVGID_6_" gradientUnits="userSpaceOnUse" x1="97.0855" y1="71.151" x2="150.9576" y2="71.151">
                  <stop offset={0} style={{stopColor: '#D93C65'}} />
                  <stop offset={1} style={{stopColor: '#FDF53F'}} />
                </linearGradient>
                <path className="st9" d="M107.2,50.3c13-9.8,36.6-17.5,43.6,27.7c1.6,19.1-5.3,27.7-33.8,16.3S95.8,58.9,107.2,50.3z" />
                <linearGradient id="dpf-SVGID_7_" gradientUnits="userSpaceOnUse" x1="-340.1659" y1="-205.2169" x2="-235.5084" y2="-243.3092" gradientTransform="matrix(0.992 -0.1266 0.1266 0.992 510.471 537.8231)">
                  <stop offset={0} style={{stopColor: '#FBB03B'}} />
                  <stop offset={1} style={{stopColor: '#E944FF'}} />
                </linearGradient>
                <path className="st10" d="M182.1,445.3c-0.1-2.3,0.1-4.2,0.4-5.3c3.5-11.1,11.3-27.3,11.3-27.3S169.2,339,162,321.4l32.3-95.6
			c0.4,9.8,1,18.1,2.2,21.9c3.5,11,20.9,140.7,22.5,156.1c0.8,7.6-0.5,36.9-1.9,64.7" />
                <linearGradient id="dpf-SVGID_8_" gradientUnits="userSpaceOnUse" x1="-381.4916" y1="-223.245" x2="-246.1367" y2="-272.5101" gradientTransform="matrix(0.992 -0.1266 0.1266 0.992 510.471 537.8231)">
                  <stop offset={0} style={{stopColor: '#FBB03B'}} />
                  <stop offset={1} style={{stopColor: '#E944FF'}} />
                </linearGradient>
                <path className="st11" d="M133.6,422.5c3.8-8.8,7.1-16.7,7.1-16.7s-24.3-100.7-21.8-116.2c2.5-15.5,23.9-37.9,22.7-47.6l52.7-16.2
			l-22.6,80c0,0,4.4,96.3,1.2,107.4c-0.8,2.8-3.5,11.4-7,22.7" />
                <path className="st3" d="M97.1,245.3c-14.3-7-25.4-15.2-27.4-22.8c-4.5-17.5-2-95.3,64.3-105.4c41.2-6.3,43.2,2.3,55.8,18.5
			c12.6,16.3,33.3,29.5,33.3,29.5l20.4,44.4L224.4,223c0,0-5.7-35-13.4-42.7c-7.7-7.7-20.8-33.4-31.3-37.5c0,0,11.8,14.2,10.2,22.4
			c-1.1,5.4-4.6,29.5-6.4,48.4l-54.3,9.4c0-9.8-25.6-32.2-26.5-39.5c0,0-6.1,25.2-9.4,27.7c-1.5,1.1,6,8.3,14.7,15.8L97.1,245.3z" />
                <linearGradient id="dpf-SVGID_9_" gradientUnits="userSpaceOnUse" x1="110.1544" y1="166.1932" x2="259.656" y2="166.1932">
                  <stop offset={0} style={{stopColor: '#E944FF'}} />
                  <stop offset={1} style={{stopColor: '#33CCFF'}} />
                </linearGradient>
                <path className="st12" d="M257.7,205.3c-1.8,6.3-4.1,12.3-7,17.8c-3.9,7.6-7.2,12.9-9.9,15.9c-4.2,5-8.9,7.9-14.4,8.8
			c-3.9,0.5-8.8,0-14.5-1.5c-5.8-1.5-11-1.9-15.6-1.3c-4.8,0.7-9.9,2.5-15.1,5.5c-5.3,3-9.6,4.8-13,5.4c-5.2,0.9-10.7-0.7-16.5-4.8
			c-3.7-2.4-8.5-6.8-14.4-13.2c-6.3-6.8-11.9-14.9-16.6-24.3c-5.1-10.2-8.3-20.3-9.7-30.3c-1.5-11.5-0.4-21.7,3.5-30.7
			c3-7.2,7.5-13.2,13.5-17.9s12.8-7.7,20.3-8.8c4.1-0.6,9.7,0,16.8,1.6c7.1,1.6,11.6,2.3,13.5,2.1c1.4-0.2,6-2.3,13.8-6.4
			c7.3-3.8,13.7-5.8,19-6.1c14.6-0.8,26.2,3.4,34.8,12.7c-11.8,9.6-16.8,21.4-14.7,35.4c1.6,10.9,6.8,19.5,15.6,25.7
			c4,2.9,8.3,4.9,12.9,6.2C259.1,199.9,258.4,202.7,257.7,205.3L257.7,205.3z M207.1,79.6c1.2,8.6-0.9,17-6.1,25.2
			c-6.3,9.8-14.8,16.1-24.8,16.7c-0.3-1-0.5-2.1-0.6-3.2c-1.1-8.2,1.3-17.5,6.7-25.6c2.7-4.1,6.3-7.6,10.9-10.7c4.6-3,9-5,13.3-5.8
			C206.7,77.4,207,78.5,207.1,79.6L207.1,79.6z" />
                <linearGradient id="dpf-SVGID_10_" gradientUnits="userSpaceOnUse" x1="97.0848" y1="244.3096" x2="181.6282" y2="244.3096">
                  <stop offset={0} style={{stopColor: '#FDF53F'}} />
                  <stop offset={1} style={{stopColor: '#D93C65'}} />
                </linearGradient>
                <path className="st13" d="M108.1,227c10,8.6,21.6,17.7,22.7,18.4c2,1.2,22.8-11.4,26.9-10.6c4.1,0.8-12.6,12.6-12.6,12.6
			s10.2,2,16.5,0.8c6.3-1.2,17.7-4.1,19.3-0.8c1.6,3.3,3.3,14.2-24.4,14.2c-15.8,0-40.4-7-59.3-16.3L108.1,227z" />
                <path className="st7" d="M141.9,51.8c0,0,15.1-45.8-44.8-16.9c0,0-45.1,30.9,0,62.3c-11.5,7.3-39.4,39.1,0,72.9
			c25.2,7.7,43.5-9.8,42.3-19.9c-1.2-10.2-33-16.7-34.6-30.9c-1.6-14.2,2.4-19.7,2.4-19.7s5.6,2.7,16.8,0c0,0-3.8-10.4-8.3-13.3
			c-4.5-2.8-15.5-8.1-11-11.8c4.5-3.7,5.7,0.5,9.8-3.2c4.1-3.7,12.1-24.2,15.9-24.7C134.2,45.9,141.9,51.8,141.9,51.8z" />
              </g>
              <linearGradient id="dpf-SVGID_11_" gradientUnits="userSpaceOnUse" x1="241.9224" y1="216.6062" x2="261.5288" y2="209.47">
                <stop offset={0} style={{stopColor: '#D93C65'}} />
                <stop offset="0.1537" style={{stopColor: '#DD5260'}} />
                <stop offset="0.4747" style={{stopColor: '#E98C55'}} />
                <stop offset="0.9329" style={{stopColor: '#FAE742'}} />
                <stop offset={1} style={{stopColor: '#FDF53F'}} />
              </linearGradient>
              <path className="st14" d="M246.3,208.4c3.2-6.7,8.6-7.8,11.1-6.7s6.1,10.8,1,16.5c-5.1,5.7-13,9.4-13.8,6S243.9,213.5,246.3,208.4z" />
            </g>
          </svg>
        </div>
        <div className="feature-content">
          <h3>Качественный результат</h3>
          <p className="feature-description">
            В наших проектах мы гарантируем чистый код, только персональные разработки для выполнения конкретных задач, что учитывается в дизайне, при верстке, программировании и при продвижении. Выполняем проекты в срок, в нужном месте и с высоким качеством. Мы придерживаемся точности в отношениях, потому что понимаем бизнес наших Партнеров.
          </p>
        </div>
      </div>

      <div className="feature animate-in beta-releases visible">
        <div className="feature-illustration">{/*?xml version="1.0" encoding="utf-8"?*/}
          <svg version="1.1" id="btf" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{enableBackground: 'new 0 0 512 512'}} xmlSpace="preserve">
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\t#btf .st0{fill:url(#btf-SVGID_1_);}\n\t#btf .st1{fill:url(#btf-SVGID_2_);}\n\t#btf .st2{fill:#33CCFF;}\n\t#btf .st3{fill:#EC0C43;}\n\t#btf .st4{fill:url(#btf-SVGID_3_);}\n\t#btf .st5{fill:url(#btf-SVGID_4_);}\n\t#btf .st6{fill:#E944FF;}\n\t#btf .st7{fill:#FBB03B;}\n\t#btf .st8{fill:url(#btf-SVGID_5_);}\n\t#btf .st9{fill:url(#btf-SVGID_6_);}\n\t#btf .st10{fill:url(#btf-SVGID_7_);}\n\t#btf .st11{fill:url(#btf-SVGID_8_);}\n\t#btf .st12{fill:url(#btf-SVGID_9_);}\n\t#btf .st13{clip-path:url(#btf-SVGID_11_);}\n\t#btf .st14{fill:url(#btf-SVGID_12_);}\n\t#btf .st15{fill:url(#btf-SVGID_13_);}\n\t#btf .st16{fill:url(#btf-SVGID_14_);}\n\t#btf .st17{fill:#F1F2F2;}\n\t#btf .st18{fill:url(#btf-SVGID_15_);}\n\t#btf .st19{fill:#188BEF;}\n\t#btf .st20{fill:url(#btf-SVGID_16_);}\n\t#btf .st21{fill:url(#btf-SVGID_17_);}\n\t#btf .st22{fill:url(#btf-SVGID_18_);}\n\t#btf .st23{fill:#FFFFFF;}\n\t#btf .st24{fill:url(#btf-SVGID_19_);}\n" }} />
            <linearGradient id="btf-SVGID_1_" gradientUnits="userSpaceOnUse" x1="51.926" y1="118.5097" x2="375.6769" y2="236.3454" gradientTransform="matrix(1 0 0 -1 0 512)">
              <stop offset={0} style={{stopColor: '#FBB03B'}} />
              <stop offset={1} style={{stopColor: '#E944FF'}} />
            </linearGradient>
            <path className="st0" d="M78.6,194.6c51-30.2,147.6,58.6,256.6,80.8c38.9,7.9,79.7,81,76.9,119.9c-8.7,121.7-209,107.1-304.6,41.5
	C41,391-31.4,259.6,78.6,194.6z" />
            <linearGradient id="btf-SVGID_2_" gradientUnits="userSpaceOnUse" x1="262.2431" y1="484.35" x2="300.7682" y2="484.35" gradientTransform="matrix(1 0 0 -1 0 512)">
              <stop offset={0} style={{stopColor: '#0944C1'}} />
              <stop offset={1} style={{stopColor: '#188BEF'}} />
            </linearGradient>
            <path className="st1" d="M298.9,24.6l-9.5-4.6c-0.1-0.1-0.2-0.1-0.3-0.3l-4.7-9.5c-0.6-1.3-1.8-1.9-3-1.9s-2.4,0.6-3,1.9l-4.6,9.5
	c-0.1,0.1-0.1,0.2-0.3,0.3l-9.5,4.7c-2.5,1.2-2.5,4.8,0,6l9.5,4.6c0.1,0.1,0.2,0.1,0.3,0.3l4.7,9.5c0.6,1.3,1.8,1.9,3,1.9
	s2.4-0.6,3-1.9l4.6-9.5c0.1-0.1,0.1-0.2,0.3-0.3l9.5-4.7C301.4,29.4,301.4,25.9,298.9,24.6z M291.8,25.3l-4.4,3.8
	c-0.9,0.8-1.5,1.8-1.7,3l-1.1,5.7c-0.1,0.4-0.5,0.5-0.8,0.2l-3.8-4.4c-0.8-0.9-1.8-1.5-3-1.7l-5.7-1.1c-0.4-0.1-0.5-0.5-0.2-0.8
	l4.4-3.8c0.9-0.8,1.5-1.8,1.7-3l1.1-5.7c0.1-0.4,0.5-0.5,0.8-0.2l3.8,4.4c0.8,0.9,1.8,1.5,3,1.7l5.7,1.1
	C292,24.6,292.1,25.1,291.8,25.3z" />
            <path className="st2" d="M334.5,57.5l-3.3-1.6l-0.1-0.1l-1.6-3.3c-0.2-0.4-0.6-0.7-1.1-0.7c-0.4,0-0.8,0.2-1.1,0.7l-1.6,3.3l-0.1,0.1
	l-3.3,1.6c-0.9,0.4-0.9,1.7,0,2.1l3.3,1.6l0.1,0.1l1.6,3.3c0.2,0.4,0.6,0.7,1.1,0.7c0.4,0,0.8-0.2,1.1-0.7l1.6-3.3l0.1-0.1l3.3-1.6
	C335.4,59.2,335.4,58,334.5,57.5z M332,57.8l-1.5,1.3c-0.3,0.3-0.5,0.6-0.6,1l-0.4,2c0,0.1-0.2,0.2-0.3,0.1l-1.3-1.5
	c-0.3-0.3-0.6-0.5-1-0.6l-2-0.4c-0.1,0-0.2-0.2-0.1-0.3l1.5-1.3c0.3-0.3,0.5-0.6,0.6-1l0.4-2c0-0.1,0.2-0.2,0.3-0.1l1.3,1.5
	c0.3,0.3,0.6,0.5,1,0.6l2,0.4C332.1,57.5,332.1,57.7,332,57.8z" />
            <path className="st3" d="M326.1,124.3l-2-1c0,0,0,0-0.1-0.1l-1-2c-0.1-0.3-0.4-0.4-0.6-0.4c-0.3,0-0.5,0.1-0.6,0.4l-1,2c0,0,0,0-0.1,0.1
	l-2,1c-0.5,0.3-0.5,1,0,1.3l2,1c0,0,0,0,0.1,0.1l1,2c0.1,0.3,0.4,0.4,0.6,0.4c0.3,0,0.5-0.1,0.6-0.4l1-2c0,0,0,0,0.1-0.1l2-1
	C326.7,125.3,326.7,124.5,326.1,124.3z M324.6,124.4l-0.9,0.8c-0.2,0.2-0.3,0.4-0.4,0.6l-0.2,1.2c0,0.1-0.1,0.1-0.2,0l-0.8-0.9
	c-0.2-0.2-0.4-0.3-0.6-0.4l-1.2-0.2c-0.1,0-0.1-0.1,0-0.2l0.9-0.8c0.2-0.2,0.3-0.4,0.4-0.6l0.2-1.2c0-0.1,0.1-0.1,0.2,0l0.8,0.9
	c0.2,0.2,0.4,0.3,0.6,0.4l1.2,0.2C324.6,124.3,324.7,124.4,324.6,124.4z" />
            <linearGradient id="btf-SVGID_3_" gradientUnits="userSpaceOnUse" x1="374.0232" y1="324.35" x2="382.2721" y2="324.35" gradientTransform="matrix(1 0 0 -1 0 512)">
              <stop offset={0} style={{stopColor: '#FDF53F'}} />
              <stop offset={1} style={{stopColor: '#D93C65'}} />
            </linearGradient>
            <path className="st4" d="M381.9,187l-2-1c0,0,0,0-0.1-0.1l-1-2c-0.1-0.3-0.4-0.4-0.6-0.4c-0.3,0-0.5,0.1-0.6,0.4l-1,2c0,0,0,0-0.1,0.1
	l-2,1c-0.5,0.3-0.5,1,0,1.3l2,1c0,0,0,0,0.1,0.1l1,2c0.1,0.3,0.4,0.4,0.6,0.4c0.3,0,0.5-0.1,0.6-0.4l1-2c0,0,0,0,0.1-0.1l2-1
	C382.4,188.1,382.4,187.3,381.9,187z M380.4,187.2l-0.9,0.8c-0.2,0.2-0.3,0.4-0.4,0.6l-0.2,1.2c0,0.1-0.1,0.1-0.2,0l-0.8-0.9
	c-0.2-0.2-0.4-0.3-0.6-0.4l-1.2-0.2c-0.1,0-0.1-0.1,0-0.2l0.9-0.8c0.2-0.2,0.3-0.4,0.4-0.6l0.2-1.2c0-0.1,0.1-0.1,0.2,0l0.8,0.9
	c0.2,0.2,0.4,0.3,0.6,0.4l1.2,0.2C380.4,187,380.4,187.1,380.4,187.2z" />
            <linearGradient id="btf-SVGID_4_" gradientUnits="userSpaceOnUse" x1="228.8345" y1="469.15" x2="251.1069" y2="469.15" gradientTransform="matrix(1 0 0 -1 0 512)">
              <stop offset={0} style={{stopColor: '#FDF53F'}} />
              <stop offset={1} style={{stopColor: '#D93C65'}} />
            </linearGradient>
            <path className="st5" d="M250,41.1l-5.5-2.7c-0.1,0-0.1-0.1-0.1-0.1l-2.7-5.5c-0.4-0.7-1.1-1.1-1.7-1.1c-0.7,0-1.4,0.4-1.7,1.1l-2.7,5.5
	c0,0.1-0.1,0.1-0.1,0.1l-5.5,2.7c-1.4,0.7-1.4,2.8,0,3.5l5.5,2.7c0.1,0,0.1,0.1,0.1,0.1l2.7,5.5c0.4,0.7,1.1,1.1,1.7,1.1
	c0.7,0,1.4-0.4,1.7-1.1l2.7-5.5c0-0.1,0.1-0.1,0.1-0.1l5.5-2.7C251.5,43.8,251.5,41.8,250,41.1z M245.9,41.4l-2.5,2.2
	c-0.5,0.4-0.9,1-1,1.7l-0.7,3.3c0,0.2-0.3,0.3-0.4,0.1l-2.2-2.5c-0.4-0.5-1-0.9-1.7-1l-3.3-0.7c-0.2,0-0.3-0.3-0.1-0.4l2.5-2.2
	c0.5-0.4,0.9-1,1-1.7l0.7-3.3c0-0.2,0.3-0.3,0.4-0.1l2.2,2.5c0.4,0.5,1,0.9,1.7,1l3.3,0.7C246,41.1,246.1,41.3,245.9,41.4z" />
            <path className="st6" d="M324.9,99.6l-5-2.5c-0.1,0-0.1-0.1-0.1-0.1l-2.5-5c-0.3-0.7-1-1-1.6-1s-1.3,0.3-1.6,1l-2.5,5
	c0,0.1-0.1,0.1-0.1,0.1l-5,2.5c-1.3,0.7-1.3,2.5,0,3.2l5,2.5c0.1,0,0.1,0.1,0.1,0.1l2.5,5c0.3,0.7,1,1,1.6,1s1.3-0.3,1.6-1l2.5-5
	c0-0.1,0.1-0.1,0.1-0.1l5-2.5C326.2,102.1,326.2,100.2,324.9,99.6z M321.1,99.9l-2.3,2c-0.5,0.4-0.8,1-0.9,1.6l-0.6,3
	c0,0.2-0.3,0.3-0.4,0.1l-2-2.3c-0.4-0.5-1-0.8-1.6-0.9l-3-0.6c-0.2,0-0.3-0.3-0.1-0.4l2.3-2c0.5-0.4,0.8-1,0.9-1.6l0.6-3
	c0-0.2,0.3-0.3,0.4-0.1l2,2.3c0.4,0.5,1,0.8,1.6,0.9l3,0.6C321.2,99.6,321.3,99.8,321.1,99.9z" />
            <path className="st7" d="M182.2,55.6l-2.1-1c0,0,0,0-0.1-0.1l-1-2.1c-0.1-0.3-0.4-0.4-0.7-0.4s-0.5,0.1-0.7,0.4l-1,2.1c0,0,0,0-0.1,0.1
	l-2.1,1c-0.6,0.3-0.6,1.1,0,1.3l2.1,1c0,0,0,0,0.1,0.1l1,2.1c0.1,0.3,0.4,0.4,0.7,0.4s0.5-0.1,0.7-0.4l1-2.1c0,0,0,0,0.1-0.1l2.1-1
	C182.8,56.7,182.8,55.9,182.2,55.6z M180.7,55.7l-1,0.8c-0.2,0.2-0.3,0.4-0.4,0.7l-0.2,1.3c0,0.1-0.1,0.1-0.2,0l-0.8-1
	c-0.2-0.2-0.4-0.3-0.7-0.4l-1.3-0.2c-0.1,0-0.1-0.1,0-0.2l1-0.8c0.2-0.2,0.3-0.4,0.4-0.7l0.2-1.3c0-0.1,0.1-0.1,0.2,0l0.8,1
	c0.2,0.2,0.4,0.3,0.7,0.4l1.3,0.2C180.7,55.6,180.7,55.7,180.7,55.7z" />
            <linearGradient id="btf-SVGID_5_" gradientUnits="userSpaceOnUse" x1="284.6057" y1="242.9708" x2="384.1155" y2="206.7522" gradientTransform="matrix(1 0 0 -1 0 512)">
              <stop offset={0} style={{stopColor: '#0944C1'}} />
              <stop offset="0.215" style={{stopColor: '#0A49C4'}} />
              <stop offset="0.4761" style={{stopColor: '#0D58CE'}} />
              <stop offset="0.7601" style={{stopColor: '#1270DE'}} />
              <stop offset={1} style={{stopColor: '#188BEF'}} />
            </linearGradient>
            <path className="st8" d="M321.7,208.8c15.5-6.6,60.7,13.6,78.5,31.5s-44.3,75.1-61.1,93.6c-6.3,7-40.6,21.5-40.6,21.5L279,290.6
	C279,290.6,294.3,220.5,321.7,208.8z" />
            <linearGradient id="btf-SVGID_6_" gradientUnits="userSpaceOnUse" x1="339.7007" y1="300.5186" x2="370.3087" y2="311.659" gradientTransform="matrix(1 0 0 -1 0 512)">
              <stop offset={0} style={{stopColor: '#FDF53F'}} />
              <stop offset={1} style={{stopColor: '#D93C65'}} />
            </linearGradient>
            <path className="st9" d="M347.8,198c0,0-1.8,8.6-6.1,10.7c-4.4,2.1,0.5,9.8,6.3,10.6s15.4-2.9,15.4-2.9s5-19,5.7-19.3s-3.3-6.5-10.3-5.7
	C351.5,192.3,347.8,198,347.8,198z" />
            <linearGradient id="btf-SVGID_7_" gradientUnits="userSpaceOnUse" x1="-187.3024" y1="974.9156" x2="-90.8426" y2="1010.0241" gradientTransform="matrix(0.9876 -0.157 -0.157 -0.9876 654.6711 1233.6951)">
              <stop offset={0} style={{stopColor: '#0944C1'}} />
              <stop offset={1} style={{stopColor: '#188BEF'}} />
            </linearGradient>
            <path className="st10" d="M317.7,275.4c7-0.3,13.8-0.4,17.5,0c9.6,1,19.3,2.4,19.3,2.4s-2.4-17.3,4.3-25.5c6.7-8.3,16.4-31.6,41.4-12
	c11,8.7-12.3,53.2-28.2,62.7c-8.8,5.2-34.8,0.7-56.3-4.4L317.7,275.4z" />
            <linearGradient id="btf-SVGID_8_" gradientUnits="userSpaceOnUse" x1="326.056" y1="327.2202" x2="390.0457" y2="350.5106" gradientTransform="matrix(1 0 0 -1 0 512)">
              <stop offset={0} style={{stopColor: '#FDF53F'}} />
              <stop offset={1} style={{stopColor: '#D93C65'}} />
            </linearGradient>
            <path className="st11" d="M371.7,142.6c9.9,6.2,30.3,20.7,12.7,42.3c-22.9,28.1-47.9,14.2-55.6,7.3C324.6,185.4,355.7,132.6,371.7,142.6
	z" />
            <linearGradient id="btf-SVGID_9_" gradientUnits="userSpaceOnUse" x1="354.6871" y1="338.3427" x2="402.7068" y2="355.8203" gradientTransform="matrix(1 0 0 -1 0 512)">
              <stop offset={0} style={{stopColor: '#E944FF'}} />
              <stop offset={1} style={{stopColor: '#33CCFF'}} />
            </linearGradient>
            <path className="st12" d="M403,157c-0.3-1.8-11.3-17.9-16.2-21.2c-3.9-2.7-9.4-3.6-14.1-3.4c-5.2,0.3-10.3,2-14.9,4.5
	c-2.8,1.5-5.7,3.3-8.8,2.8c1.4,0.9,2.9,1.9,4.3,2.8l0.6,0.6c-1.4,1.3-2.4,2.8-2.7,4.7c2.4,0.1,4.8-0.1,7.2-0.4
	c-2.6,0.7-4.5,3.4-4.2,6.1c2.7-1,5.5-1.8,8.3-2.4c0,0-0.2,6-1.5,6.8c3.2,1.1,6.4,0.1,9.6,0.8l9.9,3.4c-2,3.1-6.4,9.8-8.4,12.9
	c2.2,0.7,4.9-0.4,6.9,0.8c1.4,0.8,2.2,2.5,2.1,4.1s-0.9,3.1-1.9,4.3c-1.3,1.5-3.1,2.7-5,3.3c-0.9,0.3-1.9,0.4-2.7,0.8
	c-1,0.4-1.8,1.1-2.6,1.9c-0.7,0.6-1.3,1.2-1.8,1.9c-3.1,4.2-1,8.5-0.7,13.1c0.1,1.9,7.3-8.5,10.2-9.5c7.4-2.6,14.9-7.2,20.2-12.5
	c3.7-3.7,5.1-6.8,6.5-12.1C404.4,166.7,403.8,161.7,403,157z" />
            <g>
              <g>
                <defs>
                  <path id="SVGID_10_" d="M97,91c51-30.2,85.1-44.6,187,0c80,35,130.9,265.3,128.1,304.2c-8.7,121.7-209,107.1-304.6,41.5
				C41,391-12.9,156,97,91z" />
                </defs>
                <clipPath id="SVGID_11_">
                  <use xlinkHref="#btf-SVGID_10_" style={{overflow: 'visible'}} />
                </clipPath>
                <g className="st13">
                  <linearGradient id="btf-SVGID_12_" gradientUnits="userSpaceOnUse" x1="-165.0914" y1="751.342" x2="56.1287" y2="751.342" gradientTransform="matrix(0.9929 -0.1187 -0.1187 -0.9929 337.7347 1016.7899)">
                    <stop offset={0} style={{stopColor: '#0944C1'}} />
                    <stop offset={1} style={{stopColor: '#188BEF'}} />
                  </linearGradient>
                  <path className="st14" d="M267,460.2l-99.9,12c-33.1,4-63.1-19.6-67-52.7L69.2,161.3c-4-33.1,19.6-63.1,52.7-67l99.9-12
				c33.1-4,63.1,19.6,67,52.7l30.9,258.1C323.7,426.2,300.1,456.2,267,460.2z" />
                  <linearGradient id="btf-SVGID_13_" gradientUnits="userSpaceOnUse" x1="-191.7898" y1="701.4153" x2="82.8273" y2="801.3677" gradientTransform="matrix(0.9929 -0.1187 -0.1187 -0.9929 337.7347 1016.7899)">
                    <stop offset={0} style={{stopColor: '#E944FF'}} />
                    <stop offset={1} style={{stopColor: '#33CCFF'}} />
                  </linearGradient>
                  <path className="st15" d="M263.3,455.9l-93.7,11.2c-31.9,3.8-60.8-18.9-64.6-50.8L74.8,163.1c-3.8-31.9,18.9-60.8,50.8-64.6
				l93.7-11.2c31.9-3.8,60.8,18.9,64.6,50.8l30.3,253.2C317.9,423.2,295.2,452.1,263.3,455.9z" />
                  <linearGradient id="btf-SVGID_14_" gradientUnits="userSpaceOnUse" x1="-113.0148" y1="928.6074" x2="5.35" y2="928.6074" gradientTransform="matrix(0.9929 -0.1187 -0.1187 -0.9929 337.7347 1016.7899)">
                    <stop offset={0} style={{stopColor: '#0944C1'}} />
                    <stop offset={1} style={{stopColor: '#188BEF'}} />
                  </linearGradient>
                  <path className="st16" d="M220.3,104.3l-90.4,10.8c-7.5,0.9-14.3-4.4-15.2-11.9l-0.4-3.5l117.5-14.1l0.4,3.5
				C233.2,96.6,227.8,103.4,220.3,104.3z" />
                  <path className="st17" d="M179.8,102.5l-25.7,3.1c-1,0.1-1.8-0.6-2-1.5l-0.1-0.9c-0.1-1,0.6-1.8,1.5-2l25.7-3.1c1-0.1,1.8,0.6,2,1.5
				l0.1,0.9C181.5,101.5,180.8,102.4,179.8,102.5z" />
                  <circle className="st17" cx="192.4" cy={99} r={4} />
                  <path className="st2" d="M151.3,234.2l-19.1,2.3c-17.3,2.1-32.9-10.3-35-27.5l-2.3-19.1c-2.1-17.3,10.3-32.9,27.5-35l19.1-2.3
				c17.3-2.1,32.9,10.3,35,27.5l2.3,19.1C180.9,216.5,168.6,232.2,151.3,234.2z" />
                  <linearGradient id="btf-SVGID_15_" gradientUnits="userSpaceOnUse" x1="-44.5066" y1="825.3023" x2="37.7964" y2="855.2581" gradientTransform="matrix(0.9929 -0.1187 -0.1187 -0.9929 337.7347 1016.7899)">
                    <stop offset={0} style={{stopColor: '#0944C1'}} />
                    <stop offset={1} style={{stopColor: '#188BEF'}} />
                  </linearGradient>
                  <path className="st18" d="M249.1,222.5l-19.1,2.3c-17.3,2.1-32.9-10.3-35-27.5l-2.3-19.1c-2.1-17.3,10.3-32.9,27.5-35l19.1-2.3
				c17.3-2.1,32.9,10.3,35,27.5l2.3,19.1C278.7,204.8,266.4,220.5,249.1,222.5z" />
                  <path className="st19" d="M162.7,329.4l-19.1,2.3c-17.3,2.1-32.9-10.3-35-27.5l-2.3-19.1c-2.1-17.3,10.3-32.9,27.5-35l19.1-2.3
				c17.3-2.1,32.9,10.3,35,27.5l2.3,19.1C192.3,311.6,179.9,327.3,162.7,329.4z" />
                  <linearGradient id="btf-SVGID_16_" gradientUnits="userSpaceOnUse" x1="-44.4956" y1="729.4407" x2="37.8074" y2="759.3965" gradientTransform="matrix(0.9929 -0.1187 -0.1187 -0.9929 337.7347 1016.7899)">
                    <stop offset={0} style={{stopColor: '#FBB03B'}} />
                    <stop offset={1} style={{stopColor: '#E944FF'}} />
                  </linearGradient>
                  <path className="st20" d="M260.5,317.7l-19.1,2.3c-17.3,2.1-32.9-10.3-35-27.5l-2.3-19.1c-2.1-17.3,10.3-32.9,27.5-35l19.1-2.3
				c17.3-2.1,32.9,10.3,35,27.5l2.3,19.1C290.1,299.9,277.8,315.6,260.5,317.7z" />
                  <linearGradient id="btf-SVGID_17_" gradientUnits="userSpaceOnUse" x1="-142.9178" y1="648.6307" x2="-60.7125" y2="648.6307" gradientTransform="matrix(0.9929 -0.1187 -0.1187 -0.9929 337.7347 1016.7899)">
                    <stop offset={0} style={{stopColor: '#FDF53F'}} />
                    <stop offset={1} style={{stopColor: '#D93C65'}} />
                  </linearGradient>
                  <path className="st21" d="M174.1,424.5l-19.1,2.3c-17.3,2.1-32.9-10.3-35-27.5l-2.3-19.1c-2.1-17.3,10.3-32.9,27.5-35l19.1-2.3
				c17.3-2.1,32.9,10.3,35,27.5l2.3,19.1C203.6,406.8,191.3,422.5,174.1,424.5z" />
                  <path className="st6" d="M271.9,412.8l-19.1,2.3c-17.3,2.1-32.9-10.3-35-27.5l-2.3-19.1c-2.1-17.3,10.3-32.9,27.5-35l19.1-2.3
				c17.3-2.1,32.9,10.3,35,27.5l2.3,19.1C301.5,395.1,289.1,410.8,271.9,412.8z" />
                  <circle className="st7" cx="136.9" cy="194.6" r="22.5" />
                  <linearGradient id="btf-SVGID_18_" gradientUnits="userSpaceOnUse" x1="223.545" y1={234} x2="268.609" y2={234} gradientTransform="matrix(1 0 0 -1 0 512)">
                    <stop offset={0} style={{stopColor: '#0944C1'}} />
                    <stop offset="0.215" style={{stopColor: '#0A49C4'}} />
                    <stop offset="0.4761" style={{stopColor: '#0D58CE'}} />
                    <stop offset="0.7601" style={{stopColor: '#1270DE'}} />
                    <stop offset={1} style={{stopColor: '#188BEF'}} />
                  </linearGradient>
                  <circle className="st22" cx="246.1" cy={278} r="22.5" />
                  <path className="st2" d="M254.1,194.7l-35.5,4.2c-6.1,0.7-11.7-3.7-12.5-9.8l-0.7-5.7c-0.7-6.1,3.7-11.7,9.8-12.5l35.5-4.2
				c6.1-0.7,11.7,3.7,12.5,9.8l0.7,5.7C264.7,188.4,260.2,194,254.1,194.7z" />
                  <path className="st23" d="M179.1,396.7l-35.5,4.2c-6.1,0.7-11.7-3.7-12.5-9.8l-0.7-5.7c-0.7-6.1,3.7-11.7,9.8-12.5l35.5-4.2
				c6.1-0.7,11.7,3.7,12.5,9.8l0.7,5.7C189.6,390.4,185.2,396,179.1,396.7z" />
                </g>
              </g>
            </g>
            <linearGradient id="btf-SVGID_19_" gradientUnits="userSpaceOnUse" x1="-271.1098" y1="997.0571" x2="-179.7861" y2="997.0571" gradientTransform="matrix(0.9876 -0.157 -0.157 -0.9876 654.6711 1233.6951)">
              <stop offset={0} style={{stopColor: '#FDF53F'}} />
              <stop offset={1} style={{stopColor: '#D93C65'}} />
            </linearGradient>
            <path className="st24" d="M321.7,300.2C304.4,296.1,284,290,284,290s-20.5,1.4-22.6,1.8c-2.1,0.3-8.1-12.2-11.4-14.2
	c-3.2-2-22.5-2-22.8-6.1s26.7-8,44.7-2.5s22.3,7.7,22.3,7.7s12-0.9,23.4-1.4c0,0,3.9,6.1,4.4,11C322.9,293,321.7,300.2,321.7,300.2z
	" />
          </svg>
        </div>
        <div className="feature-content">
          <h3>Сопровождение</h3>
          <p className="feature-description">
            После окончания работ, мы поддерживаем каждый наш продукт, и помогаем в дальнейшем его развитии. Секрет нашего успеха заключается в том, что мы заинтересованы в успехе каждого проекта. Именно это создаёт нашу репутацию, которая обеспечивает дальнейшее развитие. Таким образом, ваша репутация является нашей честью!
          </p>
        </div>
      </div>
				</div>
        </section>
        <div id="users-feedback-bg-bottom"></div>
        <section id="footer-cta" className="animate-in visible">
        <div className="inner">
          <h2>Доверьте свой бизнес нам!</h2>
          <p>
            Оставьте заявку, и наш менеджер свяжется с Вами в ближайшее время
          </p>
          <div id="footer-cta-btn" onClick={this.handleClickOpen}>Заказать проект</div>
          <CallBack open={this.state.open} onClose={this.handleClose} id={"about"}/>
        </div>
      </section>
      <footer>
        <div className="inner">
          <div id="footer-content">
            <div id="footer-logo">{/*?xml version="1.0" encoding="utf-8"?*/}
            <div className="party" title="Colibri">
            <button onClick={this.playAudio}>
            <ul>
              <li />
              <li />
              <li />
              <li />
            </ul>
            </button>
            <audio className="audio-element">
              <source src={audio}></source>
            </audio>
          </div>
            </div>
            <div id="copyright">
            ©2018-{(new Date().getFullYear())} Colibrisoft | Все права защищены
          </div>
            <div id="footer-social">
              <a href="https://www.facebook.com/colibrisoftuz" target="_blank" rel="noopener noreferrer">
                <i className="icon-facebook" />
              </a>
              <a href="https://www.instagram.com/colibrisoft/" target="_blank" rel="noopener noreferrer">
                <i className="icon-instagram" />
              </a>
              <a href="https://t.me/colibrisoft/" target="_blank" rel="noopener noreferrer">
                <i className="icon-telegram" />
              </a>
            </div>
          </div>
          
        </div>
      </footer>
  </div>
    )
  }
}

export default AboutUs;
