import React from "react";
import { Link } from 'react-router-dom';
import "fullpage.js/vendors/scrolloverflow"; 
import ReactFullpage from "@fullpage/react-fullpage";
import Particles from 'react-particles-js';
import WebIcon from '../img/website-icon.png';
import MobIcon from '../img/mobile-icon.png';
import TgBotIcon from '../img/tgbot-icon-1.png';
import "animate.css/animate.min.css";
import GoogleMapReact from 'google-map-react';
import Hidden from '@material-ui/core/Hidden';
import { isMobile } from "react-device-detect";
import CallBack from "./CallBack";
import axios from 'axios';

const API = 'https://cors-anywhere.herokuapp.com/https://colibrisoftmailservice.herokuapp.com';
const telegram_bot_id = "5222471280:AAG8qu0vHzElxrMwz3dFWzEiVjdXTfHotUA";
const chat_id = 530670967;
const phoneRegex = RegExp(
    /^(\+998)?\s?\(?([0-9]){2}\)?\s?([0-9]){3}\s?-?([0-9]){2}\s?-?([0-9]){2}$/
  );
const MarkerMap = () => 
<div className="dot">
<div className="centraldot"></div>
<div className="wave"></div>
<div className="wave2"></div>
</div>;

class FullPageBody extends React.Component {

    constructor(props){
        super(props);
        this.state={
          open: false,
          fullName: null,
          phone: null,
          responseError: false,
          isWebsite: false,
          isMobApp: false,
          isTgBot: false,
          isSMM: false,
          isOther: false,
          isSent: false,
          invalidData: false,
          formErrors: {
              phone: false,
      }
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleChangeWebsite = this.toggleChangeWebsite.bind(this);
        this.toggleChangeMobApp = this.toggleChangeMobApp.bind(this);
        this.toggleChangeTgBot = this.toggleChangeTgBot.bind(this);
        this.toggleChangeSMM = this.toggleChangeSMM.bind(this);
        this.toggleChangeOther = this.toggleChangeOther.bind(this);
      }

      toggleChangeWebsite = () => {
        this.setState(prevState => ({
            isWebsite: !this.state.isWebsite,
        }));
      }

      toggleChangeMobApp = () => {
        this.setState(prevState => ({
          isMobApp: !this.state.isMobApp,
        }));
      }
    
      toggleChangeTgBot = () => {
        this.setState(prevState => ({
            isTgBot: !this.state.isTgBot,
        }));
      }

      toggleChangeSMM = () => {
        this.setState(prevState => ({
            isSMM: !this.state.isSMM,
        }));
      }
      toggleChangeOther = () => {
        this.setState(prevState => ({
            isOther: !this.state.isOther,
        }));
      }
      handleChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        let formErrors = { ...this.state.formErrors };
        
        switch (name){
          
          case "phone":
            formErrors.phone = phoneRegex.test(value)
              ? true
              : false
            break;
          default:
            break;
        }
        
        this.setState({ formErrors, [name]: value }, () => {});
      };


      handleSubmit = e => {
        e.preventDefault();
        
        this.setState({ isLoading: true });
        axios.post(`https://api.telegram.org/bot${telegram_bot_id}/sendMessage`, { chat_id: chat_id, text: `Name: ${e.target.elements.userName.value}, 
Phone: ${e.target.elements.phone.value}, 
Services: ${this.state.isWebsite ? 'Website,' : ''} ${this.state.isMobApp ? 'Mobile App,' : ''}  ${this.state.isTgBot ? 'Telegram Bot,': ''} ${this.state.isSMM ? 'SMM,' : ''}  ${this.state.isOther ? 'Other' : ''}` })
    
          .then((response) => {
            this.setState({ isLoading: false, isSent: true });
            sessionStorage.setItem('isSent', JSON.stringify(response.data));
          }).catch((error) => {
            console.log(error);
            this.setState({ responseError: true });
            this.setState({ isLoading: false });
          });
        
    };

      
      handleClickOpen = () => {
        this.setState({
          menuOpen: false,
          open: true,
        });
      };
    
      handleClose = () => {
        this.setState({ open: false });
      };

    static defaultProps = {
        center: {
          lat: 41.345994,
          lng: 69.334488
        },
        zoom: 15,
      };
    
      render() {

        const tooltips = ["Главная", "Миссия", "Услуги", "Контакты"];
        return (
            <div>
            <ReactFullpage
            navigation
            navigationTooltips={tooltips}
            responsive={1200}
            render={({ state, fullpageApi }) => {
              return (
                <ReactFullpage.Wrapper>

                  <div className="section active">
                  <div className="area">
                  <Hidden xsDown smDown implementation="css">
                  <div className="container">
                  
                  <div className="context animate-in visible">
                  
                  <svg viewBox="0 0 960 300">
                    <symbol id="s-text">
                      <text textAnchor="middle" x="50%" y="50%">Colibrisoft</text>
                    </symbol>

                    <g className = "g-ants">
                      <use xlinkHref="#s-text" className="text-copy"></use>
                      <use xlinkHref="#s-text" className="text-copy"></use>
                      <use xlinkHref="#s-text" className="text-copy"></use>
                      <use xlinkHref="#s-text" className="text-copy"></use>
                      <use xlinkHref="#s-text" className="text-copy"></use>
                    </g>
                  </svg>
                  
                  <h3>IT-решения для бизнеса</h3>
                  </div>
                  
                  <div className="scrollContainer" onClick={() => fullpageApi.moveSectionDown()}>
                  
                    <div className="chevron"></div>
                    <div className="chevron"></div>
                    <div className="chevron"></div>
                    
                </div>

                  </div>
                  
                  
                  <Particles 
                  params={{
                    "particles": {
                        "number": {
                            "value": 130,
                            "density": {
                                "enable": false
                            }
                        },
                        "size": {
                            "value": 3.5,
                            "random": true,
                            "anim": {
                                "speed": 4,
                                "size_min": 0.3
                            }
                        },
                        "line_linked": {
                            "enable": false
                        },
                        "move": {
                            "random": true,
                            "speed": 1,
                            "direction": "center",
                            "out_mode": "out"
                        }
                    },
                    "interactivity": {
                        "events": {
                            "onhover": {
                                "enable": true,
                                "mode": "bubble"
                            },
                            "onclick": {
                                "enable": false,
                                "mode": "repulse"
                            }
                        },
                        "modes": {
                            "bubble": {
                                "distance": 250,
                                "duration": 2,
                                "size": 0,
                                "opacity": 0
                            },
                            "repulse": {
                                "distance": 400,
                                "duration": 4
                            }
                        }
                    }
                }}  />
                </Hidden>
                <Hidden mdUp implementation="css">
                <div className="bg-anim">
                </div>
                  <div className="context">
                  
                  <svg viewBox="0 0 960 300">
                    <symbol id="s-text">
                      <text textAnchor="middle" x="50%" y="50%">Colibrisoft</text>
                    </symbol>

                    <g className = "g-ants">
                      <use xlinkHref="#s-text" className="text-copy"></use>
                      <use xlinkHref="#s-text" className="text-copy"></use>
                      <use xlinkHref="#s-text" className="text-copy"></use>
                      <use xlinkHref="#s-text" className="text-copy"></use>
                      <use xlinkHref="#s-text" className="text-copy"></use>
                    </g>
                  </svg>
                  
                  <h3>IT-решения для бизнеса</h3>
                  </div>
                  <div className="scrollContainer" onClick={() => fullpageApi.moveSectionDown()}>
                  
                    <div className="chevron"></div>
                    <div className="chevron"></div>
                    <div className="chevron"></div>
                    
                </div>
                  </Hidden>
          </div>
          
                  </div>
                  <div className="section slide-mission active">
                  <div className="area-2">
                  <div className="full-page darken-bg">
                      <div className="row wrapper">
                      <div className="mission-deco-sm">
                      <h3>Наша Миссия</h3>
                      </div>
                          <div className="mission-deco s12 m6 col">
                              <h3>Наша Миссия</h3>
                              </div>
                          <div className="mission-text s12 m6 col">
                              <div>
                                  <p>Быть инновационным лидером и предпочтительным партнёром, оказывая нашим клиентам качественные услуги в области информационных технологий.</p>
                              </div>
                              <Link to={"/about"} className="btn" href="/about/">Кто Мы Такие<i className="fa fa-angle-double-right arrow1" aria-hidden="true"></i></Link>
                              
                          </div>
                    </div>
                  </div>
                  </div>
                  </div>
                  <div className="section slide-insight active">
                  <div className="area-3" >
                  <ul className="circles">
                  <li></li>
                  <li></li>
                  <li></li>
          </ul>
                  <div className="full-page">
                  <h3 className="our-services-title">Что мы предлагаем?</h3>
                  <div className="insight-cards">
                  <figure>
                  <strong><img src={WebIcon} alt="Website" /> </strong>
                  <aside>
                  <em>Качественные сайты и веб-приложения любого уровня сложности</em>
                  <Link to={"/services"}>
                  <span className="duration">Подробно</span>
                  </Link>
                  </aside>
                  </figure>
                  <figure>
                  <strong><img src={MobIcon} alt="Mobile App" /></strong>
                  <aside>
                  <em>Мобильные приложения отвечающие требованиям мировых стандартов</em>
                  <Link to={"/services"}>
                  <span className="duration">Подробно</span>
                  </Link>
                  </aside>
                  </figure>
                  <figure>
                  <strong><img src={TgBotIcon} alt="Telegram Bot" /></strong>
                  <aside>
                  <em>Умные телеграм боты для упрощения и повышения эффективности бизнеса</em>
                  <Link to={"/services"}>
                  <span className="duration">Подробно</span>
                  </Link>
                  </aside>
                  </figure>
                  </div>
                  <Link to={"/services"} className="btn">
                      Все Услуги<i className="fa fa-angle-double-right arrow1" aria-hidden="true"></i>
                  </Link>
                  </div>
                  </div>
                  </div>
                  <div className="section">
                  <GoogleMapReact
          options={{
            panControl: false,
            mapTypeControl: false,
            scrollwheel: false,
            disableDefaultUI: true,
            styles: [
              {
                  "featureType": "water",
                  "elementType": "geometry",
                  "stylers": [
                      {
                          "color": "#111130"
                      },
                      {
                          "lightness": 17
                      }
                  ]
              },
              {
                  "featureType": "landscape",
                  "elementType": "geometry",
                  "stylers": [
                      {
                          "color": "#111130"
                      },
                      {
                          "lightness": 20
                      }
                  ]
              },
              {
                  "featureType": "road.highway",
                  "elementType": "geometry.fill",
                  "stylers": [
                      {
                          "color": "#111130"
                      },
                      {
                          "lightness": 17
                      }
                  ]
              },
              {
                  "featureType": "road",
                  "elementType": "geometry.stroke",
                  "stylers": [
                      {
                          "visibility": "off"
                      }
                  ]
              },
              {
                  "featureType": "road.highway.controlled_access",
                  "elementType": "geometry.stroke",
                  "stylers": [
                      {
                          "color": "#111130"
                      },
                      {
                          "lightness": 20
                      }
                  ]
              },
              {
                  "featureType": "road.arterial",
                  "elementType": "geometry",
                  "stylers": [
                      {
                          "color": "#111130"
                      },
                      {
                          "lightness": 25
                      }
                  ]
              },
              {
                  "featureType": "road.local",
                  "elementType": "geometry",
                  "stylers": [
                      {
                          "color": "#111130"
                      },
                      {
                          "lightness": 25
                      }
                  ]
              },
              {
                  "featureType": "poi",
                  "elementType": "geometry",
                  "stylers": [
                      {
                          "color": "#111130"
                      },
                      {
                          "lightness": 21
                      }
                  ]
              },
              {
                  "elementType": "labels.text.stroke",
                  "stylers": [
                      {
                          "visibility": "off"
                      }
                  ]
              },
              {
                  "elementType": "labels.text.fill",
                  "stylers": [
                      {
                          "saturation": 0
                      },
                      {
                          "color": "#BBBBBB"
                      },
                      {
                          "lightness": 0
                      }
                  ]
              },
              {
                  "elementType": "labels.icon",
                  "stylers": [
                      {
                          "visibility": "off"
                      }
                  ]
              },
              {
                  "featureType": "transit",
                  "elementType": "geometry",
                  "stylers": [
                      {
                          "color": "#111130"
                      },
                      {
                          "lightness": 19
                      }
                  ]
              },
              {
                  "featureType": "administrative",
                  "elementType": "geometry.fill",
                  "stylers": [
                      {
                          "color": "#111130"
                      },
                      {
                          "lightness": 20
                      }
                  ]
              },
              {
                  "featureType": "administrative",
                  "elementType": "geometry.stroke",
                  "stylers": [
                      {
                          "color": "#111130"
                      },
                      {
                          "lightness": 17
                      },
                      {
                          "weight": 1.2
                      }
                  ]
              }
          ]

          }
        }
        bootstrapURLKeys={{ key: 'AIzaSyB0nNC2JY5h2LxGdKCTXSXMV5ZNDrpwvvA' }}
        // defaultCenter={this.props.center}
        center={isMobile ? this.props.center : {lat: 41.338950, lng: 69.317345}}
        defaultZoom={this.props.zoom}
      >
      <MarkerMap
        lat={41.338473}
        lng={69.334565}
      />
      </GoogleMapReact>
      <Hidden xsDown smDown implementation="css">
                  <div className="ontop">
                  <div className="contact-row">
                  <div className="contact-col boxes">
                  {!this.state.isLoading ? 
                    <div>
                    {!this.state.isSent && !sessionStorage.getItem('isSent') ? 
                  <form onSubmit={this.handleSubmit} className="browser-default left-align" noValidate="">
                  
                  <div className="form-group">
                            <input type="text" name="userName" onChange={this.handleChange} className="form-control" placeholder="Как к вам обращаться?" required/>
                        </div>
                        <div className="form-group">
                            <input type="text" name="phone" onChange={this.handleChange} className="form-control" defaultValue="+998 " placeholder="+998 (__) ___-__-__" required/>
                        </div>
                        
                        <div className="cbx">
                        <p>Что вас интересует?</p>
                          <input type="checkbox" id="box-1" onChange={this.toggleChangeWebsite} />
                          <label htmlFor="box-1">Создание сайта</label>

                          <input type="checkbox" id="box-2" onChange={this.toggleChangeMobApp}/>
                          <label htmlFor="box-2">Создание мобильного приложения</label>

                          <input type="checkbox" id="box-3" onChange={this.toggleChangeTgBot}/>
                          <label htmlFor="box-3">Создание телеграм бота</label>

                          <input type="checkbox" id="box-4" onChange={this.toggleChangeSMM}/>
                          <label htmlFor="box-4">Поддержка и продвижение</label>

                          <input type="checkbox" id="box-5" onChange={this.toggleChangeOther}/>
                          <label htmlFor="box-5">Другое</label>
                        </div>
                        
                  <input type="hidden" name="form_id" value="1" /><br /><button type="submit" className="btn" disabled={!this.state.formErrors.phone}>Отправить<i className="fa fa-angle-double-right arrow1" aria-hidden="true"></i></button>
                  </form>
                  :
                  <div className="request-is-sent">
                        <h1>ЗАЯВКА БЫЛА ОТПРАВЛЕНА</h1>
                        <p>Мы свяжемся с Вами в ближайшее рабочее время с 10:00 до 18:00. Время обработки заявки может занять от 1 до 15 минут.
                        </p>
                  </div>
                    }
                  </div>
                  :
                  <div className="loader loader-1">
                    <div className="loader-outter"></div>
                    <div className="loader-inner"></div>
                  </div>
                  }
                  </div>
                  <div className="contact-col boxes">
                  <div className="address">
                  <h1>Наши Контакты</h1>
                  <p>Свяжитесь с нами или оставьте заявку для получения более подробной информации о наших услугах</p>
                  <h3><a href="tel:+998903552060">+998 90 355-20-60</a></h3>
                  </div>
                  <div id="footer-bottom">
                  <div className="social-links">
                  <a href="https://www.facebook.com/colibrisoftuz" target="_blank" rel="noopener noreferrer">
                  <i className="icon-facebook"></i>
                  </a>
                  <a href="https://www.instagram.com/colibrisoft/" target="_blank" rel="noopener noreferrer">
                  <i className="icon-instagram"></i>
                  </a>
                  <a href="https://t.me/colibrisoft/" target="_blank" rel="noopener noreferrer">
                  <i className="icon-telegram"></i>
                  </a>
                  <div id="main-copyright">
                  ©2018-{(new Date().getFullYear())} Colibrisoft | Все права защищены
                  </div>
                  </div>
                  
                  </div>
                  
                  </div>
                  </div>
                  </div>
                  </Hidden>
                  

                  <Hidden mdUp implementation="css">
                  <div className="ontop">
                  <div className="row contact-row-sm">
                  <div className="col">
                  <div className="address">
                  <h1>Наши Контакты</h1>
                  <p>Свяжитесь с нами или оставьте заявку для получения более подробной информации о наших услугах</p>
                  <h3><a href="tel:+998903552060">+998 90 355-20-60</a></h3>
                  <button onClick={this.handleClickOpen} className="btn">
                      Оставить заявку<i className="fa fa-angle-double-right arrow1" aria-hidden="true"></i>
                  </button>
                  <CallBack open={this.state.open} onClose={this.handleClose} id={"mob-footer"}/>
                  </div>
                  <div id="footer-bottom">
                  <div className="social-links">
                  <a href="https://www.facebook.com/colibrisoftuz" target="_blank" rel="noopener noreferrer">
                  <i className="icon-facebook"></i>
                  </a>
                  <a href="https://www.instagram.com/colibrisoft/" target="_blank" rel="noopener noreferrer">
                  <i className="icon-instagram"></i>
                  </a>
                  <a href="https://t.me/colibrisoft/" target="_blank" rel="noopener noreferrer">
                  <i className="icon-telegram"></i>
                  </a>
                  
                  </div>
                  <div id="copyright">
                  ©2018-{(new Date().getFullYear())} Colibrisoft | Все права защищены
                  </div>
                  </div>
                  </div>
                  
                  </div>
                  </div>
                  
                  </Hidden>
                    </div>
                  
                </ReactFullpage.Wrapper>
              );
            }}
          />

          
          </div>
        );
      }
    }

export default FullPageBody;