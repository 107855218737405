import React from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import ScrollToTop from 'react-router-scroll-top'
import ReactGA from 'react-ga';

import Preloader from './components/Preloader';
import Header from './components/Header';
import FullPageBody from './components/FullPageBody';
import AboutUs from './components/AboutUs';
import OurServices from './components/OurServices';
import Contact from './components/Contact';

import './App.css';

ReactGA.initialize('UA-218308747-1');

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    setInterval(() => {
      this.setState({isLoading: false})
    }, 2500)
}

  
  render() {

    return (
      <div>
      {this.state.isLoading ? <Preloader/> : 
        <BrowserRouter>
        <ScrollToTop>
        <Header />
              <Route path='/' exact component={ FullPageBody } />
              <Route path='/about' component={ AboutUs } />
              <Route path='/services' component={ OurServices } />
              <Route path='/contact' component={ Contact } />
        </ScrollToTop>
       </BrowserRouter>
       }
      </div>
    )
  }
}

export default App;
