import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import axios from 'axios';

const API = 'https://cors-anywhere.herokuapp.com/https://colibrisoftmailservice.herokuapp.com';
const telegram_bot_id = "5222471280:AAG8qu0vHzElxrMwz3dFWzEiVjdXTfHotUA";
const chat_id = 530670967;

const phoneRegex = RegExp(
    /^(\+998)?\s?\(?([0-9]){2}\)?\s?([0-9]){3}\s?-?([0-9]){2}\s?-?([0-9]){2}$/
  );

const styles = theme => ({
    root: {
      margin: 0,
      paddingLeft: '20px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
  const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles(theme => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  
  
  class CallBack extends React.Component {
    constructor(props){
      super(props);
      this.state={
        open: false,
        fullName: null,
          phone: null,
          responseError: false,
          isWebsite: false,
          isMobApp: false,
          isTgBot: false,
          isSMM: false,
          isOther: false,
          isSent: false,
          invalidData: false,
          formErrors: {
              phone: false,
      }
      }
      this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleChangeWebsite = this.toggleChangeWebsite.bind(this);
        this.toggleChangeMobApp = this.toggleChangeMobApp.bind(this);
        this.toggleChangeTgBot = this.toggleChangeTgBot.bind(this);
        this.toggleChangeSMM = this.toggleChangeSMM.bind(this);
        this.toggleChangeOther = this.toggleChangeOther.bind(this);
    }
  
    toggleChangeWebsite = () => {
      this.setState(prevState => ({
          isWebsite: !this.state.isWebsite,
      }));
    }
  
    toggleChangeMobApp = () => {
      this.setState(prevState => ({
        isMobApp: !this.state.isMobApp,
      }));
    }
  
    toggleChangeTgBot = () => {
      this.setState(prevState => ({
          isTgBot: !this.state.isTgBot,
      }));
    }
  
    toggleChangeSMM = () => {
      this.setState(prevState => ({
          isSMM: !this.state.isSMM,
      }));
    }
    toggleChangeOther = () => {
      this.setState(prevState => ({
          isOther: !this.state.isOther,
      }));
    }
    handleChange = e => {
      e.preventDefault();
      const { name, value } = e.target;
      let formErrors = { ...this.state.formErrors };
      
      switch (name){
        
        case "phone":
          formErrors.phone = phoneRegex.test(value)
            ? true
            : false
          break;
        default:
          break;
      }
      
      this.setState({ formErrors, [name]: value }, () => {});
    };
  
  
    handleSubmit = e => {
      e.preventDefault();
      
      this.setState({ isLoading: true });
      axios.post(`https://api.telegram.org/bot${telegram_bot_id}/sendMessage`, { chat_id: chat_id, text: `Name: ${e.target.elements.userName.value}, 
Phone: ${e.target.elements.phone.value}, 
Services: ${this.state.isWebsite ? 'Website,' : ''} ${this.state.isMobApp ? 'Mobile App,' : ''}  ${this.state.isTgBot ? 'Telegram Bot,': ''} ${this.state.isSMM ? 'SMM,' : ''}  ${this.state.isOther ? 'Other' : ''}` })
  
        .then((response) => {
          this.setState({ isLoading: false, isSent: true });
          sessionStorage.setItem('isSent', JSON.stringify(response.data));
        }).catch((error) => {
          console.log(error);
          this.setState({ responseError: true });
          this.setState({ isLoading: false });
        });
      
  };
  
    render() {
      const { fullScreen } = this.props;
      return (
        
        <Dialog
        TransitionComponent={Transition}
        keepMounted
        onClose={this.props.onClose}
        aria-labelledby="customized-dialog-title"
        open={this.props.open}
        fullScreen={fullScreen}
      >
      {!this.state.isSent && !sessionStorage.getItem('isSent') ? 
        <DialogTitle id="customized-dialog-title" onClose={this.props.onClose}>
        Оставьте заявку
        </DialogTitle>
        :
        <DialogTitle id="customized-dialog-title" onClose={this.props.onClose}>
        Заявка была отправлена
        </DialogTitle>  
       }
        <DialogContent style={{background: '#0A46C2', padding: '20px', paddingBottom: '25px', minWidth: '300px'}}>
        {!this.state.isLoading ? 
          <div>
          {!this.state.isSent && !sessionStorage.getItem('isSent') ? 
        <form className="browser-default left-align" noValidate="" onSubmit={this.handleSubmit}>
                    
        <div className="form-group">
                  <input type="text" className="form-control" name="userName" onChange={this.handleChange} placeholder="Как к вам обращаться?" required/>
              </div>
              <div className="form-group">
                  <input type="text" className="form-control" defaultValue="+998 " name="phone" onChange={this.handleChange} placeholder="+998 (__) ___-__-__" required/>
              </div>
              <div className="cbx">
                          <p style={{color: '#ddd'}}>Что вас интересует?</p>
                            <input type="checkbox" id={this.props.id+"box-1"} onChange={this.toggleChangeWebsite}/>
                            <label htmlFor={this.props.id+"box-1"}>Создание сайта</label>
  
                            <input type="checkbox" id={this.props.id+"box-2"} onChange={this.toggleChangeMobApp}/>
                            <label htmlFor={this.props.id+"box-2"}>Создание мобильного приложения</label>
  
                            <input type="checkbox" id={this.props.id+"box-3"} onChange={this.toggleChangeTgBot}/>
                            <label htmlFor={this.props.id+"box-3"}>Создание телеграм бота</label>
  
                            <input type="checkbox" id={this.props.id+"box-4"} onChange={this.toggleChangeSMM}/>
                            <label htmlFor={this.props.id+"box-4"}>Поддержка и продвижение</label>
  
                            <input type="checkbox" id={this.props.id+"box-5"} onChange={this.toggleChangeOther}/>
                            <label htmlFor={this.props.id+"box-5"}>Другое</label>
                          </div>
              <div style={{textAlign: 'center'}}>
        <input type="hidden" name="form_id" value="1" /><br /><button type="submit" className="btn" disabled={!this.state.formErrors.phone}>Отправить<i className="fa fa-angle-double-right arrow1" aria-hidden="true"></i></button>
        </div>
        </form>
        :
                  <div className="request-is-sent">
                        
                        <p>Мы свяжемся с Вами в ближайшее рабочее время с 10:00 до 18:00. Время обработки заявки может занять от 1 до 15 минут.
                        </p>
                  </div>
                    }
                    </div>
                    :
                  <div className="call-back-loader loader-1">
                    <div className="loader-outter"></div>
                    <div className="loader-inner"></div>
                  </div>
                  }
        </DialogContent>
  
      </Dialog>
          
      )
    }
  }
  
  CallBack.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
  };

  export default  withMobileDialog()(CallBack);