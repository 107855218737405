import React from 'react';
import CallBack from "./CallBack";
import Particles from 'react-particles-js';
import audio from "../audio/oi-colibri.mp3";

class OurServices extends React.Component {

  constructor(props){
    super(props);
    this.state={
      open: false,
    }
  }
  
  handleClickOpen = () => {
    this.setState({
      menuOpen: false,
      open: true,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  
  playAudio = () => {
    const audioEl = document.getElementsByClassName("audio-element")[0]
    audioEl.play()
  }

  render() {

    return (
      <div>
      <div id="main-bg-anim">
            <Particles 
                  params={{
                    "particles": {
                        "number": {
                            "value": 120,
                            "density": {
                                "enable": false
                            }
                        },
                        "size": {
                            "value": 3.5,
                            "random": true,
                            "anim": {
                                "speed": 4,
                                "size_min": 0.3
                            }
                        },
                        "line_linked": {
                            "enable": false
                        },
                        "move": {
                            "random": true,
                            "speed": 1,
                            "direction": "center",
                            "out_mode": "out"
                        }
                    },
                    "interactivity": {
                        "events": {
                            "onhover": {
                                "enable": true,
                                "mode": "bubble"
                            },
                            "onclick": {
                                "enable": false,
                                "mode": "repulse"
                            }
                        },
                        "modes": {
                            "bubble": {
                                "distance": 250,
                                "duration": 2,
                                "size": 0,
                                "opacity": 0
                            },
                            "repulse": {
                                "distance": 400,
                                "duration": 4
                            }
                        }
                    }
                }}  />
		</div>
      <section id="services-hero" style={{opacity: 1}}>
            
                    <div className="inner">
                    <div id="hero-content">
                    <h1>
                    Что мы делаем?
                    </h1> 
                </div>
                <div id="hero-content-bg">
                    <h1>
                    Что мы делаем?
                    </h1> 
                </div>
                
            </div>
            <div className="scroll-downs">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
          </div>
    </section>
    <div id="hero-bg-bottom"></div>
    <section id="features">
		<div className="inner">
                <div id="features-header" className="animate-in visible">
                <h2>Мы делаем то, что любим и любим то, что делаем...</h2>
        <p className="feature-description">
        Помогаем клиентам построить результативный бизнес при помощи интернет-маркетинга. Проектируем и создаем продающие лендинги, корпоративные сайты, интернет-магазины, сайты с индивидуальным решением, мобильные приложения, телеграм боты. Также мы предоставляем услуги профессионального графического дизайна, на случай его отсутствия. Мы делаем хорошую рекламу в Интернете и в социальных сетях Facebook, Instagram с максимальным эффектом. Мы заботимся, чтобы реализованные нами проекты приносили успех своим владельцам.
                    </p>
                </div>
                </div>
                
      <div className="promo">
      <div className="card-wrapper">
        <div className="cols">
          <div className="card-col">
            <div className="card-container">
              <div className="front" style={{backgroundImage: 'url(https://images.unsplash.com/photo-1481487196290-c152efe083f5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=830&q=80)'}}>
                <div className="card-inner">
                  <p>Landing-page</p>
                  <span>
                  от 2 млн сум
                  </span>
                </div>
              </div>
              <div className="back">
                <div className="card-inner">
                  <p>Лендинг формируется таким образом, чтобы коротко и доходчиво изложить суть предложения, вызвать заинтересованность, убедить клиента в том, что это то чего он желает.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-col">
            <div className="card-container">
              <div className="front" style={{backgroundImage: 'url(https://images.unsplash.com/photo-1519222970733-f546218fa6d7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80)'}}>
                <div className="card-inner">
                  <p>Корпоративные сайты</p>
                  <span>от 4 млн сум
                  </span>
                </div>
              </div>
              <div className="back">
                <div className="card-inner">
                  <p>Корпоративный сайт – это сайт компании, ее официальное виртуальное представительство в интернете.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-col">
            <div className="card-container">
              <div className="front" style={{backgroundImage: 'url(https://images.unsplash.com/photo-1542744095-291d1f67b221?ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80)'}}>
                <div className="card-inner">
                  <p>Интернет-магазины</p>
                  <span>от 10 млн сум
                  </span>
                </div>
              </div>
              <div className="back">
                <div className="card-inner">
                  <p>Мы создаем интернет-магазины на готовых движках, а также уникальные магазины, подключаем национальные и зарубежные способы оплаты.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-col">
            <div className="card-container">
              <div className="front" style={{backgroundImage: 'url(https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=702&q=80)'}}>
                <div className="card-inner">
                  <p>Уникальные Веб-Приложения</p>
                  <span>от 12 млн сум
                  </span>
                </div>
              </div>
              <div className="back">
                <div className="card-inner">
                  <p>В зависимости от сложности Вашей задачи мы можем разработать корпоративный веб-сайт, портал или торговую площадку.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-col">
            <div className="card-container">
              <div className="front" style={{backgroundImage: 'url(https://images.unsplash.com/photo-1519336367661-eba9c1dfa5e9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80'}}>
                <div className="card-inner">
                  <p>Мобильные приложения</p>
                  <span>от 12 млн сум
                  </span>
                </div>
              </div>
              <div className="back">
                <div className="card-inner">
                  <p>Разработка мобильных приложений. Дизайн — прототипирование, программирование и тех поддержка.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-col">
            <div className="card-container">
              <div className="front" style={{backgroundImage: 'url(https://images.unsplash.com/photo-1521931961826-fe48677230a5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80)'}}>
                <div className="card-inner">
                  <p>Telegram боты</p>
                  <span>от 1 млн сум
                  </span>
                </div>
              </div>
              <div className="back">
                <div className="card-inner">
                  <p>Нужен Telegram-бот? Любая сложность, архитектура и алгоритмы работы ботов.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-col">
            <div className="card-container">
              <div className="front" style={{backgroundImage: 'url(https://images.unsplash.com/photo-1498075702571-ecb018f3752d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=757&q=80)'}}>
                <div className="card-inner">
                  <p>Графический дизайн</p>
                  <span>от 1 млн сум
                  </span>
                </div>
              </div>
              <div className="back">
                <div className="card-inner">
                  <p>Уникальные логотипы, формирование брендбука и ребрендинг.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-col">
            <div className="card-container">
              <div className="front" style={{backgroundImage: 'url(https://images.unsplash.com/photo-1516321497487-e288fb19713f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80)'}}>
                <div className="card-inner">
                  <p>Продвижение в социальных сетях</p>
                  <span>от 1,5 млн сум
                  </span>
                </div>
              </div>
              <div className="back">
                <div className="card-inner">
                  <p>Профессиональное ведение страниц, грамотная настройка таргетированной рекламы.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h4><sup>*</sup>Окончательная стоимость услуг и срок разработки может варьироваться исходя из ваших потребностей, пожеланий и сложности технического задания.</h4>

      </div>
        
      </div>
      </section>
      <div id="users-feedback-bg-bottom"></div>
        <section id="footer-cta" className="animate-in visible">
        <div className="inner">
          <h2>Доверьте свой бизнес нам!</h2>
          <p>
            Оставьте заявку, и наш менеджер свяжется с Вами в ближайшее время
          </p>
          <div id="footer-cta-btn" onClick={this.handleClickOpen}>Заказать проект</div>
          <CallBack open={this.state.open} onClose={this.handleClose} id={"about"}/>
        </div>
      </section>
      <footer>
        <div className="inner">
          <div id="footer-content">
            <div id="footer-logo">{/*?xml version="1.0" encoding="utf-8"?*/}
            <div className="party" title="Colibri">
            <button onClick={this.playAudio}>
            <ul>
              <li />
              <li />
              <li />
              <li />
            </ul>
            </button>
            <audio className="audio-element">
              <source src={audio}></source>
            </audio>
          </div>
            </div>
            <div id="copyright">
            ©2018-{(new Date().getFullYear())} Colibrisoft | Все права защищены
          </div>
            <div id="footer-social">
              <a href="https://www.facebook.com/colibrisoftuz" target="_blank" rel="noopener noreferrer">
                <i className="icon-facebook" />
              </a>
              <a href="https://www.instagram.com/colibrisoft/" target="_blank" rel="noopener noreferrer">
                <i className="icon-instagram" />
              </a>
              <a href="https://t.me/colibrisoft/" target="_blank" rel="noopener noreferrer">
                <i className="icon-telegram" />
              </a>
            </div>
          </div>
          
        </div>
      </footer>
      </div>
    )
  }
}

export default OurServices;
